var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useCallback, useEffect, useState } from "react";
import { isFuelParams, } from "../components/fleet-calculator/calculator-types";
import { mapValues, pick } from "lodash";
var getOption = function (record, value) { return ({
    value: value,
    label: record[value],
}); };
var getOptions = function (record) {
    return Object.keys(record).map(function (key) { return getOption(record, key); });
};
var getFuel = function (fuelType, metadata) {
    return metadata.fuels[fuelType];
};
export var getAvailableEngineTypes = function (fuelType, metadata) {
    return metadata.combinations.fuels[fuelType];
};
export var useParams = function (_a) {
    var defaultParams = _a.defaultParams, setParams = _a.setParams;
    var _b = useState(defaultParams), localParams = _b[0], setLocalParams = _b[1];
    var setParamsHelper = useCallback(function (newParams) {
        setLocalParams(function (prevParams) { return (__assign(__assign({}, prevParams), newParams)); });
    }, []);
    useEffect(function () {
        setParams(localParams);
    }, [localParams, setParams]);
    return {
        localParams: localParams,
        setParamsHelper: setParamsHelper,
    };
};
export var useFuelParams = function (_a) {
    var defaultParams = _a.defaultParams, metadata = _a.metadata, isComparison = _a.isComparison, fuelIndex = _a.fuelIndex, setParams = _a.setParams;
    var _b = useState(defaultParams), localParams = _b[0], setLocalParams = _b[1];
    var _c = useState(isFuelParams(localParams)
        ? getAvailableEngineTypes(localParams.fuel_type ? localParams.fuel_type.toString() : "", metadata)
        : []), availableEngineTypes = _c[0], setAvailableEngineTypes = _c[1];
    var setParamsHelper = useCallback(function (newParams) {
        setLocalParams(function (prevParams) { return (__assign(__assign({}, prevParams), newParams)); });
    }, []);
    useEffect(function () {
        setParams(localParams, isComparison, fuelIndex);
    }, [localParams, setParams, isComparison, fuelIndex]);
    var fuelTypes = mapValues(metadata.fuels, function (fuel) { return fuel.description; });
    var engineTypes = mapValues(metadata.engines, function (engine) { return engine.engine.description; });
    var fuelName = localParams.fuel_type && fuelTypes[localParams.fuel_type];
    var engineOptions = getOptions(pick(engineTypes, availableEngineTypes));
    var selectedEngineOption = getOption(engineTypes, localParams.engine_type ? localParams.engine_type.toString() : "");
    var onChangeFuelType = useCallback(function (fuelType, metadata) {
        var fuelNumber = Number(fuelType);
        var fuel = getFuel(fuelNumber, metadata);
        var availableEngineTypes = getAvailableEngineTypes(fuelType, metadata);
        var newEngineType = localParams.engine_type !== undefined &&
            availableEngineTypes.includes(localParams.engine_type)
            ? localParams.engine_type
            : availableEngineTypes[0];
        setAvailableEngineTypes(availableEngineTypes);
        setParamsHelper({
            fuel_type: fuelNumber,
            fuel_price: fuel.price,
            engine_type: newEngineType,
            engine_change_cost: fuel.default_engine_change_cost,
            engine_efficiency: metadata["efficiency-fuel-engine"][fuelType][newEngineType],
            intensity: isComparison
                ? metadata["intensity-fuel-engine"][fuelType][newEngineType]
                : undefined,
        });
    }, [metadata, localParams, setParamsHelper, setAvailableEngineTypes]);
    var onChangeEngineType = useCallback(function (engineType) {
        if (localParams.fuel_type) {
            setParamsHelper({
                engine_type: Number(engineType),
                engine_efficiency: metadata["efficiency-fuel-engine"][localParams.fuel_type][engineType],
                intensity: isComparison
                    ? metadata["intensity-fuel-engine"][localParams.fuel_type][engineType]
                    : undefined,
            });
        }
    }, [metadata, localParams, setParamsHelper, setAvailableEngineTypes]);
    return {
        localParams: localParams,
        fuelName: fuelName,
        engineOptions: engineOptions,
        selectedEngineOption: selectedEngineOption,
        onChangeFuelType: onChangeFuelType,
        onChangeEngineType: onChangeEngineType,
        setParamsHelper: setParamsHelper,
    };
};
