var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { scaleLinear } from "d3-scale";
import { withParentDimensions, } from "../generic/withParentDimensions";
import { theme } from "../../theme";
import { GraphLabel, GraphTitle, PSmall } from "../common-styled-components";
import { useMemo, useState } from "react";
import styled from "styled-components";
import Select from "react-select";
import { smallSelectStyle } from "./SmallSelect";
export var getComparisonColor = function (i) {
    var _a;
    switch (i) {
        case 0:
            return theme.colors.grey(2);
        default:
            return (_a = theme.colors.greenScale[i - 1]) !== null && _a !== void 0 ? _a : "black";
    }
};
var ChartContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  margin-top: ", ";\n  margin-bottom: ", ";\n  overflow: visible;\n"], ["\n  width: 100%;\n  margin-top: ", ";\n  margin-bottom: ", ";\n  overflow: visible;\n"])), function (p) { return (p.noMargin ? 0 : theme.spacing(4)); }, function (p) { return (p.noMargin ? 0 : theme.spacing(5)); });
var getOption = function (valueSeries) { return ({
    value: valueSeries.selectorLabel,
    label: valueSeries.selectorLabel,
}); };
export default withParentDimensions(function (_a) {
    var width = _a.parentDimensions.width, unit = _a.unit, valueSeries = _a.valueSeries, hideDifference = _a.hideDifference, maxDigits = _a.maxDigits, details = _a.details;
    var _b = useState(valueSeries[0]), selectedSeries = _b[0], setSelectedSeries = _b[1];
    var finalWidth = useMemo(function () { return Math.min(320, width); }, [width]);
    var values = selectedSeries.values;
    if (width <= 0 || !values) {
        return _jsx("div", {});
    }
    var maximumFractionDigits = maxDigits !== null && maxDigits !== void 0 ? maxDigits : 2;
    var margins = {
        top: 15,
        bottom: 0,
        left: 4,
        right: 1,
    };
    var numberValues = values.map(function (v) { return v.value; });
    var minValue = Math.min.apply(Math, numberValues);
    var maxValue = Math.max.apply(Math, numberValues);
    var labelWidth = maxValue.toLocaleString("en-US", { maximumFractionDigits: maximumFractionDigits }).length * 14;
    var rowHeight = 30;
    var contentHeight = values.length * rowHeight;
    var height = margins.top + margins.bottom + contentHeight;
    var fontSize = theme.fontSizeNumber(-1);
    var xScale = scaleLinear()
        .domain([Math.min(minValue, 0), maxValue])
        .range([margins.left, finalWidth - margins.right - labelWidth]);
    var change = getChange(values);
    var changeString = hideDifference || change === undefined
        ? ""
        : " ".concat(change < 0 ? "" : "+").concat(change.toFixed(1), " %");
    var zeroLine = xScale(0);
    return (_jsxs(ChartContainer, __assign({ noMargin: true }, { children: [selectedSeries.title ? (_jsxs(GraphTitle, { children: [selectedSeries.title, " ", unit ? "(".concat(unit, ")") : "", changeString] })) : undefined, details !== undefined && _jsx(PSmall, { children: details }), _jsxs("svg", __assign({ width: finalWidth, height: height }, { children: [_jsx("line", { x1: zeroLine, x2: zeroLine, y1: margins.top, y2: height, stroke: theme.colors.grey(1), strokeWidth: 1 }), values.map(function (v, i) {
                        var x = xScale(v.value);
                        var y = margins.top + i * rowHeight + rowHeight / 2;
                        var color = v.color;
                        return (_jsxs("g", { children: [_jsx("line", { y1: y, y2: y, x1: zeroLine, x2: x, stroke: color, strokeWidth: 2, strokeLinecap: "butt" }), _jsx("circle", { cy: y, cx: x, r: 4, fill: color, strokeWidth: 0 }), _jsx(GraphLabel, __assign({ y: y + fontSize / 2, x: Math.max(x + 8, zeroLine + 8) }, { children: v.value.toLocaleString("en-US", {
                                        maximumFractionDigits: maximumFractionDigits,
                                    }) }))] }, v.name));
                    })] })), valueSeries.length > 1 && (_jsx(Select, { options: valueSeries.map(getOption), onChange: function (newValue) {
                    var newSeries = valueSeries.find(function (s) { return s.selectorLabel === (newValue === null || newValue === void 0 ? void 0 : newValue.value); });
                    if (newSeries) {
                        setSelectedSeries(newSeries);
                    }
                }, value: getOption(selectedSeries), isOptionSelected: function (option) {
                    return selectedSeries.selectorLabel === option.label;
                }, styles: smallSelectStyle }))] })));
});
function getChange(values) {
    if (values.length === 2) {
        var originalValue = values[0].value;
        var comparisonValue = values[1].value;
        return ((comparisonValue - originalValue) / originalValue) * 100;
    }
    return undefined;
}
var templateObject_1;
