import { jsx as _jsx } from "react/jsx-runtime";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import { isAdmin } from "../util/auth";
export var ProtectedRoute = function (_a) {
    var needsAdmin = _a.needsAdmin, children = _a.children;
    var _b = useAuth0(), user = _b.user, isAuthenticated = _b.isAuthenticated;
    if (!isAuthenticated) {
        return _jsx(Navigate, { to: "/", replace: true });
    }
    if (needsAdmin && !isAdmin(user)) {
        return _jsx(Navigate, { to: "/", replace: true });
    }
    return children !== null && children !== void 0 ? children : null;
};
