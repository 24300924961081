var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getAuthHeader } from "../util/auth";
import useFetch from "./useFetch";
import { useDeepMemo } from "./useDeepMemo";
import { mapValues } from "lodash";
import { getCarbonIntensity } from "../util/carbonIntensity";
import { useAuth0 } from "@auth0/auth0-react";
import { LoaderStatus } from "../util/apiUtils";
export var useMetadata = function () {
    var user = useAuth0().user;
    var rawMetadata = useFetch("calculator/data.json", undefined, __assign(__assign({}, getAuthHeader(user)), { "Content-Type": "application/json" }))[0];
    var metadata = useDeepMemo(function () {
        if (rawMetadata.status !== LoaderStatus.Success) {
            return [undefined];
        }
        return [
            __assign(__assign({}, rawMetadata.data), { fuels: mapValues(rawMetadata.data.fuels, function (f) { return (__assign(__assign({}, f), { intensity_text: getCarbonIntensity(f.average_intensity, rawMetadata.data.intensity_limit), average_intensity: Number(f.average_intensity) })); }) }),
        ];
    }, [rawMetadata])[0];
    return [metadata];
};
