var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useParams } from "react-router-dom";
import { P, PageContent, SectionTitle } from "../../common-styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import useFetch from "../../../hooks/useFetch";
import { getAuthHeader } from "../../../util/auth";
import { LoaderStatus } from "../../../util/apiUtils";
import { useMetadata } from "../../../hooks/useMetadata";
export default (function () {
    var poolId = useParams().poolId;
    var user = useAuth0().user;
    var _a = useFetch("admin/pools.json", 
    // JSON.stringify({
    //   pool_id: poolId,
    // }),
    undefined, __assign(__assign({}, getAuthHeader(user)), { "Content-Type": "application/json" })), pools = _a[0], _setUrl = _a[1], _setBody = _a[2], _refresh = _a[3];
    var voyages = useFetch("admin/voyages.json", undefined, __assign(__assign({}, getAuthHeader(user)), { "Content-Type": "application/json" }))[0];
    var usages = useFetch("admin/usages.json", undefined, __assign(__assign({}, getAuthHeader(user)), { "Content-Type": "application/json" }))[0];
    var metadata = useMetadata()[0];
    // const { intensities, fuelSpend } = useDeepMemo(() => {
    //   if (metadata) {
    //     const { reports } = getSimulatedReportsAndFleet(0, metadata);
    //     return analyzeReports(reports, metadata, 2025, 2025);
    //   }
    //   return { intensities: undefined, fuelSpend: undefined };
    // }, [metadata]);
    var poolData = pools.status === LoaderStatus.Success
        ? pools.data.find(function (pool) { return pool.id.toString() === poolId; })
        : null;
    var voyagesData = voyages.status === LoaderStatus.Success ? voyages.data : null;
    var usageData = usages.status === LoaderStatus.Success ? usages.data : null;
    console.log(pools);
    console.log(voyages);
    console.log(usages);
    if (!pools ||
        pools.status === LoaderStatus.Idle ||
        pools.status === LoaderStatus.Loading) {
        return _jsx(PageContent, { children: "Loading\u2026" });
    }
    if (poolData && metadata) {
        return (_jsxs(PageContent, { children: [_jsx(SectionTitle, { children: poolData.name }), _jsxs(P, { children: [poolData.ships.length, " ships"] })] }));
    }
    return _jsxs(PageContent, { children: ["Pool ", poolId, " not found!"] });
});
