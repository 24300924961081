var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Button, PageContent, SmallButton, TD, TH, TR, TitleWithExtraContent, } from "../../common-styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import useFetch from "../../../hooks/useFetch";
import { getAuthHeader } from "../../../util/auth";
import useEdit from "../../../hooks/useEdit";
import { Modal } from "./Modal";
import { LoaderStatus } from "../../../util/apiUtils";
import { isNullish } from "../../../util/isNullish";
var tableColumns = [
    {
        key: "name",
        label: "Name",
    },
    {
        key: "ships",
        label: "Ships",
    },
    // {
    //   key: "currentIntensity",
    //   label: "Intensity",
    // },
    // {
    //   key: "currentTotalCO2eEmissionTonnes",
    //   label: "Emissions (tCO2e)",
    // },
    {
        key: "tools",
        label: "Tools",
    },
];
var CellContent = function (_a) {
    var fleet = _a.fleet, contentKey = _a.contentKey, editFleet = _a.editFleet, removeFleet = _a.removeFleet;
    switch (contentKey) {
        case "ships":
            return _jsx("div", { children: fleet.ships.length });
        case "tools":
            return (_jsxs("div", { children: [_jsx(SmallButton, __assign({ onClick: function () { return removeFleet(fleet); } }, { children: "Remove" })), _jsx(SmallButton, __assign({ onClick: function () { return editFleet(fleet); } }, { children: "Edit" }))] }));
        default:
            return _jsx("div", { children: fleet[contentKey] });
    }
};
export default (function () {
    var user = useAuth0().user;
    var _a = useFetch("admin/fleets.json", undefined, __assign(__assign({}, getAuthHeader(user)), { "Content-Type": "application/json" })), fleets = _a[0], _setUrl = _a[1], _setBody = _a[2], refresh = _a[3];
    var _b = useEdit("admin/fleet.json", __assign(__assign({}, getAuthHeader(user)), { "Content-Type": "application/json" }), refresh), editStatus = _b[0], executeEdit = _b[1];
    var _c = useState({}), modal = _c[0], setModal = _c[1];
    var addFleet = function () {
        setModal({
            type: "add",
            value: undefined,
        });
    };
    var editFleet = function (fleet) {
        setModal({
            type: "edit",
            value: fleet,
        });
    };
    var removeFleet = function (fleet) {
        setModal({
            type: "remove",
            value: fleet,
        });
    };
    var submit = function (type, value) {
        setModal({});
        if (type === "add") {
            if (value.name && value.name !== "" && !isNullish(value.company_id)) {
                var data = {
                    name: value.name,
                    company_id: value.company_id,
                    id: null,
                };
                executeEdit(data, "PUT");
            }
            else {
                alert("Empty name or fleet id");
            }
        }
        if (type === "edit") {
            if (!isNullish(value.name) &&
                value.name !== "" &&
                !isNullish(value.company_id) &&
                !isNullish(value.id)) {
                var data = {
                    name: value.name,
                    company_id: value.company_id,
                    id: value.id,
                };
                executeEdit(data, "PUT");
            }
            else {
                alert("Empty name");
            }
        }
        if (type === "remove") {
            if (value.id !== undefined) {
                var data = {
                    id: value.id,
                };
                executeEdit(data, "DELETE");
            }
        }
    };
    return (_jsxs(PageContent, { children: [modal.type && (_jsx(Modal, { type: "fleet", isOpen: modal.type !== undefined, closeModal: function () { return setModal({}); }, modalType: modal.type, initialValue: modal.value, submit: submit })), _jsx(TitleWithExtraContent, __assign({ titleLevel: "Section", extraContent: _jsx(Button, __assign({ onClick: addFleet }, { children: "+ Add" })) }, { children: "Fleets" })), _jsxs("table", { children: [_jsx("thead", { children: _jsxs("tr", { children: [tableColumns.map(function (c) { return (_jsx(TH, { children: c.label }, c.key)); }), " "] }) }), _jsx("tbody", { children: fleets.status === LoaderStatus.Success &&
                            fleets.data.map(function (fleet) { return (_jsx(TR, { children: tableColumns.map(function (c) { return (_jsx(TD, { children: _jsx(CellContent, { contentKey: c.key, fleet: fleet, removeFleet: removeFleet, editFleet: editFleet }) }, c.key)); }) }, fleet.id)); }) })] })] }));
});
