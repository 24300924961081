var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useDeepMemo } from "../../hooks/useDeepMemo";
import { theme } from "../../theme";
import { Grid, Legend, LegendItem, SectionTitle, UISection, } from "../common-styled-components";
import CompareBarChart, { getComparisonColor } from "../custom/CompareBarChart";
import { PrivateContentVeil } from "../generic/PrivateContentVeil";
import TimelineChart from "../custom/TimelineChart";
import { calculateSurplusResults, getPricePerGJ, } from "../../util/calculatorUtils";
import { MAX_SMALL_CELL_WIDTH } from "../constants";
import { every } from "lodash";
export default (function (_a) {
    var _b, _c, _d;
    var params = _a.params, fuelTypes = _a.fuelTypes, calculation = _a.calculation, metadata = _a.metadata, texts = _a.texts;
    var resultDetails = useDeepMemo(function () { return calculateSurplusResults(calculation); }, [calculation, params, fuelTypes]);
    var originalFuel = resultDetails.originalFuel, cumulativeCosts = resultDetails.cumulativeCosts, cumulativeEmissions = resultDetails.cumulativeEmissions, originalPathTimeline = resultDetails.originalPathTimeline, lastAccumulationYear = resultDetails.lastAccumulationYear, complianceData = resultDetails.complianceData, maxCost = resultDetails.maxCost;
    var cost_per_exceeded_emission_ton = {
        name: "Cost per exceeded emission ton (original fuel)",
        value: (_d = (_c = (_b = calculation.original_fuel.timeline
            .find(function (d) { return d.year === 2025; })) === null || _b === void 0 ? void 0 : _b.cost_per_exceeded_emission_ton.find(function (d) { return d.with_emissions === false; })) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : 0,
        color: getComparisonColor(0),
    };
    return (_jsx("div", { children: calculation && resultDetails && (_jsxs(_Fragment, { children: [_jsxs(UISection, { children: [_jsx(SectionTitle, { children: "Overview" }), _jsx(TimelineChart, { title: "Yearly carbon surplus", 
                            // subTitle="The regulated carbon intensity target timeline, along with your energy sources' carbon intensities." // {texts.carbon_intensity_targets}
                            unit: "tCO\u2082e", forceDigits: 1, data: [
                                {
                                    name: "Surplus",
                                    color: theme.colors.blue,
                                    values: calculation.co2_surplus,
                                    hideCircles: true,
                                },
                                // {
                                //   name: texts.target_for_each_year,
                                //   color: theme.colors.blue,
                                //   values: Object.keys(metadata.intensity).map((year) => ({
                                //     year: Number(year),
                                //     value: metadata.intensity[year],
                                //   })),
                                // },
                            ], chartType: "stepAfter" }), _jsxs(Grid, __assign({ maxCellWidth: MAX_SMALL_CELL_WIDTH }, { children: [(originalFuel === null || originalFuel === void 0 ? void 0 : originalFuel.fuel_cost_per_ton) !== undefined &&
                                    every(params.comparison_fuels, function (f) { return f.fuel_price !== undefined; }) && (_jsx("div", { children: _jsx(CompareBarChart, { unit: "USD/t", valueSeries: [
                                            {
                                                title: "Fuel price",
                                                selectorLabel: "Fuel price",
                                                values: [
                                                    {
                                                        name: originalFuel.fuel_name,
                                                        color: getComparisonColor(0),
                                                        value: originalFuel.fuel_cost_per_ton,
                                                    },
                                                ],
                                            },
                                        ] }) })), _jsx("div", { children: _jsx(CompareBarChart, { unit: "MJ/g", maxDigits: 3, valueSeries: [
                                            {
                                                title: "LCV",
                                                selectorLabel: "LCV",
                                                values: [
                                                    {
                                                        name: originalFuel.fuel_name,
                                                        color: getComparisonColor(0),
                                                        value: originalFuel.energy_content,
                                                    },
                                                ],
                                            },
                                        ] }) }), _jsx("div", { children: _jsx(CompareBarChart, { unit: "USD/GJ", valueSeries: [
                                            {
                                                title: "Energy cost",
                                                selectorLabel: "Energy cost",
                                                values: [
                                                    {
                                                        name: originalFuel.fuel_name,
                                                        color: getComparisonColor(0),
                                                        value: getPricePerGJ(originalFuel.fuel_cost_per_ton, originalFuel.energy_content),
                                                    },
                                                ],
                                            },
                                        ] }) }), _jsx("div", { children: _jsx(CompareBarChart, { unit: "gCO2e/MJ", valueSeries: [
                                            {
                                                title: "Carbon intensity",
                                                selectorLabel: "Carbon intensity",
                                                values: [
                                                    {
                                                        name: originalFuel.fuel_name,
                                                        color: getComparisonColor(0),
                                                        value: calculation.carbon_intensity,
                                                    },
                                                ],
                                            },
                                        ] }) }), _jsx("div", { children: _jsx(CompareBarChart, { unit: "% of 2025 target", valueSeries: [
                                            {
                                                title: "Carbon intensity",
                                                selectorLabel: "Carbon intensity",
                                                values: [
                                                    {
                                                        name: originalFuel.fuel_name,
                                                        color: getComparisonColor(0),
                                                        value: (originalFuel.intensity /
                                                            metadata.intensity[2025]) *
                                                            100,
                                                    },
                                                ],
                                            },
                                        ] }) }), _jsx("div", { children: _jsx(CompareBarChart, { unit: "$/t", 
                                        // details="The original fuel value is the cost per exceeded emission ton,
                                        // ie. the excess cost of using the current fuel."
                                        details: "Cost of staying compliant in FuelEU Maritime regulation.\n                  For the fossil fuel, this is the penalty cost, and for the alternatives, this\n                  is the energy cost premium.", valueSeries: [
                                            {
                                                title: "Cost per reduced CO₂e ton 2025",
                                                selectorLabel: "Fuel cost",
                                                values: [cost_per_exceeded_emission_ton],
                                            },
                                            {
                                                title: "Cost per reduced CO₂e ton 2025, incl. EU ETS costs",
                                                selectorLabel: "incl. EU ETS costs",
                                                values: [cost_per_exceeded_emission_ton],
                                            },
                                        ], hideDifference: true }) })] })), _jsx(Legend, { children: _jsx(LegendItem, { color: theme.colors.grey(2), text: originalFuel.fuel_name }) })] }), _jsxs(UISection, { children: [_jsx(TimelineChart, { title: "Fuel EU Maritime carbon intensity targets", subTitle: "The regulated carbon intensity target timeline, along with your energy sources' carbon intensities." // {texts.carbon_intensity_targets}
                            , unit: "gCO\u2082e/MJ", forceZero: true, forceDigits: 1, data: [
                                {
                                    name: "".concat(originalFuel.fuel_name, " intensity"),
                                    color: theme.colors.grey(2),
                                    values: Object.keys(metadata.intensity).map(function (year) { return ({
                                        year: Number(year),
                                        value: calculation.carbon_intensity,
                                    }); }),
                                    hideCircles: true,
                                },
                                {
                                    name: texts.target_for_each_year,
                                    color: theme.colors.blue,
                                    values: Object.keys(metadata.intensity).map(function (year) { return ({
                                        year: Number(year),
                                        value: metadata.intensity[year],
                                    }); }),
                                },
                            ], chartType: "stepAfter" }), _jsx(TimelineChart, { title: texts.penalty_title ||
                                "Yearly emission penalties with current energy source", subTitle: "Yearly penalties just using ".concat(originalFuel.fuel_name, ", converted to $"), unit: "$", data: [
                                {
                                    name: originalFuel.fuel_name,
                                    color: theme.colors.grey(2),
                                    values: calculation.penalty,
                                },
                            ], canDivide: true, forceZero: true, chartType: "stepAfter" })] }), _jsxs(UISection, { children: [_jsx(SectionTitle, { children: "Original path" }), _jsx(PrivateContentVeil, __assign({ title: "Total CO\u2082e emissions and total costs 2025-".concat(lastAccumulationYear) }, { children: _jsxs(Grid, { children: [_jsx("div", { children: _jsx(CompareBarChart, { unit: texts.weight_unit, valueSeries: [
                                                {
                                                    title: "Total CO\u2082e emissions 2025-".concat(lastAccumulationYear),
                                                    selectorLabel: "Total CO\u2082e emissions 2025-".concat(lastAccumulationYear),
                                                    values: [
                                                        {
                                                            name: "Original",
                                                            value: cumulativeEmissions.original,
                                                            color: getComparisonColor(0),
                                                        },
                                                    ],
                                                },
                                            ] }) }), _jsx("div", { children: _jsx(CompareBarChart, { unit: "M$", valueSeries: [
                                                {
                                                    title: "Total cost 2025-".concat(lastAccumulationYear),
                                                    selectorLabel: "Total cost 2025-".concat(lastAccumulationYear),
                                                    values: [
                                                        {
                                                            name: "Original",
                                                            value: cumulativeCosts.original / 1000000,
                                                            color: getComparisonColor(0),
                                                        },
                                                    ],
                                                },
                                            ] }) })] }) })), _jsx(Legend, { children: _jsx(LegendItem, { color: theme.colors.grey(2), text: originalFuel.fuel_name }) }), _jsx(PrivateContentVeil, __assign({ title: "Yearly cost analysis" }, { children: _jsx(TimelineChart, { title: "Yearly cost analysis", unit: "$", canDivide: true, forceZero: true, errorYearMessage: texts.no_compliance, 
                                // complianceData={complianceData.combined}
                                data: [
                                    {
                                        name: "Cost of plan with ".concat(originalFuel.fuel_name),
                                        color: theme.colors.grey(2),
                                        values: originalPathTimeline,
                                    },
                                ], chartType: "stepAfter" }) })), _jsx(PrivateContentVeil, __assign({ title: "Cost breakdown of original path (".concat(originalFuel.fuel_name, ")") }, { children: _jsx(TimelineChart, { subTitle: "Cost breakdown of original path (".concat(originalFuel.fuel_name, ")"), unit: "$", canDivide: true, forceZero: true, 
                                // forcedMaxValue={maxCost} don't force same scale as below
                                errorYearMessage: texts.no_compliance, data: [
                                    {
                                        name: "Fuel cost",
                                        color: theme.colors.grey(2),
                                        values: originalPathTimeline.map(function (v) { return ({
                                            year: v.year,
                                            value: v.fuel_cost,
                                        }); }),
                                    },
                                    {
                                        name: "Emission cost",
                                        color: theme.colors.grey(1),
                                        values: originalPathTimeline.map(function (v) { return ({
                                            year: v.year,
                                            value: v.emission_cost,
                                        }); }),
                                    },
                                    {
                                        name: "Penalties",
                                        color: theme.colors.grey(0),
                                        values: originalPathTimeline.map(function (v) { return ({
                                            year: v.year,
                                            value: v.penalty,
                                        }); }),
                                    },
                                ], chartType: "bar" }) }))] }), _jsx(UISection, {})] })) }));
});
