import { geoNaturalEarth1, geoPath, geoTransverseMercator, } from "d3-geo";
import { useMemo } from "react";
import { feature } from "topojson-client";
import mapTopoJSON from "../../data/ne_110m_land.json";
// Calculate projection translation from zoom level and offset
var getTranslate = function (width, height, zoom) { return [
    (zoom.k * width) / 2 + zoom.x,
    (zoom.k * height) / 2 + zoom.y,
]; };
export var getProjection = function (width, height, heightFactor, zoomState) {
    var scaleFactor = Math.min(width, height / heightFactor) * 0.22;
    return geoNaturalEarth1()
        .rotate([-14, 0.0])
        .center([0.0, 14])
        .translate(getTranslate(width, height, zoomState))
        .scale(scaleFactor * zoomState.k)
        .precision(0.1);
};
export var getRegionProjection = function (width, height, heightFactor, zoomState) {
    var scaleFactor = Math.min(width, height / heightFactor) * 1.2;
    return geoTransverseMercator()
        .rotate([-118.5715027, 0])
        .center([0, -8])
        .translate([width / 2, height / 2])
        .scale(scaleFactor * zoomState.k)
        .precision(0.1);
};
export var useMapFeatures = function () {
    var mapFeatures = useMemo(function () {
        var features = feature(mapTopoJSON, mapTopoJSON.objects["ne_110m_land"]);
        return [features];
    }, [])[0];
    return [mapFeatures];
};
export var getShapes = function (projection, geometry) {
    var generator = geoPath(projection);
    return geometry.features.map(function (f) { return generator(f.geometry); });
};
