export var isCalculatorParams = function (obj) {
    return (obj &&
        // Properties specific to FleetCalculatorParams
        typeof obj.fleet_size === "number" &&
        (obj.emission_allowance_price === undefined ||
            typeof obj.emission_allowance_price === "number") &&
        (obj.year === undefined || typeof obj.year === "number") &&
        // Fuels
        Array.isArray(obj.fuels) &&
        obj.fuels.every(isOriginFuelParams) &&
        Array.isArray(obj.comparison_fuels) &&
        obj.comparison_fuels.every(isAlternativeFuelParams));
};
// Additionally, define a type guard for FuelParams as it's used in FleetCalculatorParams
export var isOriginFuelParams = function (obj) {
    return (obj &&
        (obj.fuel_price === undefined || typeof obj.foc === "number") &&
        typeof obj.fuel_type === "number" &&
        typeof obj.engine_type === "number" &&
        (obj.fuel_price === undefined || typeof obj.fuel_price === "number") &&
        (obj.engine_efficiency === undefined ||
            typeof obj.engine_efficiency === "number"));
};
export var isAlternativeFuelParams = function (obj) {
    return (obj &&
        typeof obj.fuel_type === "number" &&
        typeof obj.engine_type === "number" &&
        typeof obj.fuel_price === "number" &&
        typeof obj.engine_efficiency === "number" &&
        typeof obj.engine_change_cost === "number" &&
        typeof obj.intensity === "number");
};
export var isFuelParams = function (obj) {
    return isOriginFuelParams(obj) || isAlternativeFuelParams(obj);
};
