var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";
import { breakpoints, theme } from "../../theme";
import { PageTitle } from "../common-styled-components";
import shipLow from "../../../images/ship-intensity-low.png";
import { useNavigate } from "react-router-dom";
import { routeNames } from "../../util/routes";
import { isAdmin } from "../../util/auth";
var HeaderContainer = styled.header(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  border-bottom: 1px solid ", ";\n  padding: ", " ", ";\n  margin-bottom: ", ";\n\n  @media (max-width: ", "px) {\n    flex-direction: column;\n    align-items: center;\n    justify-content: flex-start;\n    padding: ", " 0;\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  border-bottom: 1px solid ", ";\n  padding: ", " ", ";\n  margin-bottom: ", ";\n\n  @media (max-width: ", "px) {\n    flex-direction: column;\n    align-items: center;\n    justify-content: flex-start;\n    padding: ", " 0;\n  }\n"])), theme.colors.grey(4), theme.spacing(3), theme.spacing(4), theme.spacing(6), breakpoints.mobilePlus, theme.spacing(3));
var HeaderThings = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"])));
var UserNameCol = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  gap: 2px;\n  @media (max-width: ", "px) {\n    align-items: center;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  gap: 2px;\n  @media (max-width: ", "px) {\n    align-items: center;\n  }\n"])), breakpoints.mobilePlus);
var LogButton = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border: none;\n  border-bottom: 1px solid ", ";\n  ", ";\n  padding: 0;\n  background-color: transparent;\n  color: ", ";\n  cursor: pointer;\n"], ["\n  border: none;\n  border-bottom: 1px solid ", ";\n  ", ";\n  padding: 0;\n  background-color: transparent;\n  color: ", ";\n  cursor: pointer;\n"])), theme.colors.bluelink, theme.fontSize(0), theme.colors.bluelink);
var ShipImage = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  height: 48px;\n  margin-right: ", ";\n"], ["\n  height: 48px;\n  margin-right: ", ";\n"])), theme.spacing(2));
var Tools = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  gap: 8px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  gap: 8px;\n"])));
export var Header = function () {
    var navigate = useNavigate();
    return (_jsxs(HeaderContainer, { children: [_jsxs(HeaderThings, { children: [_jsx(ShipImage, { src: shipLow }), _jsx(PageTitle, __assign({ onClick: function (e) {
                            e.preventDefault();
                            navigate(routeNames.main);
                        } }, { children: "Ahti Calculator" }))] }), _jsx(HeaderThings, { children: _jsx(UserSection, {}) })] }));
};
var UserSection = function () {
    var _a = useAuth0(), loginWithRedirect = _a.loginWithRedirect, isAuthenticated = _a.isAuthenticated, user = _a.user, logout = _a.logout;
    var prod = process.env.NODE_ENV === "production";
    var navigate = useNavigate();
    return (_jsxs(UserNameCol, { children: [user && _jsx("div", { children: user.name ? user.name : user.email }), _jsxs(Tools, { children: [!prod && isAuthenticated && isAdmin(user) && (_jsx(LogButton, __assign({ onClick: function (e) {
                            e.preventDefault();
                            navigate("".concat(routeNames.admin, "/").concat(routeNames.poolsAdmin));
                        } }, { children: "Admin" }))), isAuthenticated ? (_jsx(LogButton, __assign({ onClick: function (e) {
                            e.preventDefault();
                            logout({
                                logoutParams: { returnTo: window.location.origin },
                            });
                        } }, { children: "Log out" }))) : (_jsx(LogButton, __assign({ onClick: function (e) {
                            e.preventDefault();
                            loginWithRedirect();
                        } }, { children: "Log in" })))] })] }));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
