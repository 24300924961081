import { linearRegression, linearRegressionLine } from "simple-statistics";
import { subMonths } from "date-fns";
var getDataWithCutoffDate = function (data, newestMonths) {
    if (newestMonths === undefined || !data.length) {
        return data;
    }
    var dates = data.map(function (point) { return point.date; });
    var lastDate = dates[1];
    var dateCutoff = subMonths(lastDate, newestMonths);
    var filteredData = data.filter(function (d) { return d.date > dateCutoff; });
    if (filteredData.length < 3) {
        // Ensure there is enough to work with
        return data;
    }
    return filteredData;
};
export var predictEOY = function (data, newestMonths) {
    if (!data.length) {
        return undefined;
    }
    var dataWithCutoff = getDataWithCutoffDate(data, newestMonths);
    if (!dataWithCutoff.length) {
        return undefined;
    }
    var firstDateMS = dataWithCutoff[0].date.getTime();
    var regressionValues = dataWithCutoff.map(function (point) { return [
        point.date.getTime() - firstDateMS,
        point.value,
    ]; });
    var regression = linearRegression(regressionValues);
    var regressionLine = linearRegressionLine(regression);
    var predictionYear = dataWithCutoff[dataWithCutoff.length - 1].date.getFullYear();
    var predictionEOYDate = new Date(predictionYear, 11, 31);
    var prediction = regressionLine(predictionEOYDate.getTime() - firstDateMS);
    return {
        regression: regression,
        regressionLine: regressionLine,
        predictionEOY: { date: predictionEOYDate, value: prediction },
    };
};
