var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { theme } from "../../theme";
import styled from "styled-components";
import TimelineChart from "../custom/TimelineChart";
import { useState } from "react";
import { flatMap, some } from "lodash";
import shipLow from "../../../images/ship-intensity-low.png";
import shipMedium from "../../../images/ship-intensity-medium.png";
import shipHigh from "../../../images/ship-intensity-high.png";
import shipNone from "../../../images/ship-intensity-none.png";
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  margin-bottom: ", ";\n"], ["\n  width: 100%;\n  margin-bottom: ", ";\n"])), theme.spacing(6));
var Ships = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(auto-fill, 28px);\n  gap: 2px;\n  margin: ", " 0 ", " 0;\n"], ["\n  display: grid;\n  grid-template-columns: repeat(auto-fill, 28px);\n  gap: 2px;\n  margin: ", " 0 ", " 0;\n"])), theme.spacing(3), theme.spacing(5));
var ShipImage = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  transition: visibility 0s, opacity 0.5s linear;\n"], ["\n  transition: visibility 0s, opacity 0.5s linear;\n"])));
var getShipIcon = function (key, intensityText) {
    if (intensityText === undefined) {
        return (_jsx(ShipImage, { src: shipNone, style: { width: 24, height: 24 } }, key));
    }
    switch (intensityText) {
        case "low":
            return (_jsx(ShipImage, { src: shipLow, style: { width: 24, height: 24 } }, key));
        case "medium":
            return (_jsx(ShipImage, { src: shipMedium, style: { width: 24, height: 24 } }, key));
        case "high":
            return (_jsx(ShipImage, { src: shipHigh, style: { width: 24, height: 24 } }, key));
    }
};
export default (function (props) {
    var _a = useState(), selectedYear = _a[0], setSelectedYear = _a[1];
    var getFleetMix = function () {
        if (!selectedYear) {
            return undefined;
        }
        var fleetMix = props.fuels.map(function (fuel, i) {
            var _a, _b;
            var fuelValue = (_b = (_a = props.data[i]) === null || _a === void 0 ? void 0 : _a.values.find(function (v) { return v.year === selectedYear; })) === null || _b === void 0 ? void 0 : _b.value;
            return {
                fuel: fuel,
                amount: fuelValue,
            };
        });
        if (some(fleetMix, function (m) { return m.amount === undefined; })) {
            return undefined;
        }
        return fleetMix;
    };
    var getShips = function () {
        var fleetMix = getFleetMix();
        if (!fleetMix) {
            return __spreadArray([], Array(props.fleetSize), true).map(function (n, i) {
                return getShipIcon("none-".concat(i), undefined);
            });
        }
        return flatMap(fleetMix, function (f) {
            return __spreadArray([], Array(f.amount), true).map(function (n, i) {
                return getShipIcon("".concat(f.fuel.fuel, "-").concat(i), f.fuel.intensity_text);
            });
        });
    };
    return (_jsxs(Root, { children: [_jsx(TimelineChart, __assign({}, props, { onChange: setSelectedYear, noMargin: true })), props.fleetSize <= 40 ? _jsx(Ships, { children: getShips() }) : undefined] }));
});
var templateObject_1, templateObject_2, templateObject_3;
