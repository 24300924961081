var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ReactModal from "react-modal";
import { Button, SecondaryButton } from "../../../common-styled-components";
import { useState } from "react";
import styled from "styled-components";
import { theme } from "../../../../theme";
import { every } from "lodash";
import { ShipEditor } from "./ShipEditor";
import { PoolEditor } from "./PoolEditor";
import { CompanyEditor } from "./CompanyEditor";
import { TransactionEditor } from "./TransactionEditor";
import { FleetEditor } from "./FleetEditor";
import { isNullish } from "../../../../util/isNullish";
var getModalActionLabel = function (modalType) {
    switch (modalType) {
        case "add":
            return "Add";
        case "edit":
            return "Save";
        case "remove":
            return "Remove";
        default:
            return "undefined action (shouldn't happen)";
    }
};
var modalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        minWidth: "280px",
        maxWidth: "640px",
    },
};
var Buttons = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  margin-top: ", ";\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  margin-top: ", ";\n"])), theme.spacing(3));
var canSubmit = function (type, state) {
    switch (type) {
        case "company":
            var company = state;
            return !isNullish(company.name) && company.name !== "";
        case "ship":
            var ship = state;
            return (!isNullish(ship.name) &&
                ship.name !== "" &&
                !isNullish(ship.fleet_id) &&
                !isNullish(ship.engines) &&
                ship.engines.length > 0 &&
                every(ship.engines, function (engine) {
                    return !isNullish(engine.type) && !isNullish(engine.ship_engine_type);
                }));
        case "pool":
            var pool = state;
            return !isNullish(pool.name) && pool.name !== "";
        case "fleet":
            var fleet = state;
            return (!isNullish(fleet.name) &&
                fleet.name !== "" &&
                !isNullish(fleet.company_id));
        case "transaction":
            var transaction = state;
            return !isNullish(transaction.fromShip) && !isNullish(transaction.toShip);
        default:
            return false;
    }
};
export var Modal = function (_a) {
    var isOpen = _a.isOpen, initialValue = _a.initialValue, type = _a.type, modalType = _a.modalType, closeModal = _a.closeModal, submit = _a.submit;
    var _b = useState(initialValue !== null && initialValue !== void 0 ? initialValue : {}), modalState = _b[0], setModalState = _b[1];
    var updateValue = function (key, newValue) {
        setModalState(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[key] = newValue, _a)));
        });
    };
    var renderEditor = function () {
        if (!modalType) {
            return _jsx("div", {});
        }
        switch (type) {
            case "company":
                return (_jsx(CompanyEditor, { updateValue: updateValue, modalType: modalType, state: modalState }));
            case "ship":
                return (_jsx(ShipEditor, { updateValue: updateValue, modalType: modalType, state: modalState }));
            case "pool":
                return (_jsx(PoolEditor, { updateValue: updateValue, modalType: modalType, state: modalState }));
            case "fleet":
                return (_jsx(FleetEditor, { updateValue: updateValue, modalType: modalType, state: modalState }));
            case "transaction":
                return (_jsx(TransactionEditor, { updateValue: updateValue, modalType: modalType, state: modalState }));
            default:
                return null;
        }
    };
    return (_jsxs(ReactModal, __assign({ isOpen: isOpen, style: modalStyles }, { children: [renderEditor(), _jsxs(Buttons, { children: [_jsx(SecondaryButton, __assign({ onClick: function () { return closeModal(); } }, { children: "Close" })), modalType !== undefined && (_jsx(Button, __assign({ onClick: function () { return submit(modalType, modalState); }, disabled: !canSubmit(type, modalState) }, { children: getModalActionLabel(modalType) })))] })] })));
};
var templateObject_1;
