var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { chain, max, min, range } from "lodash";
import { useDeepMemo } from "../../hooks/useDeepMemo";
import { theme } from "../../theme";
import { GraphLabel, GraphSubTitle, GraphTitle, Legend, LegendItem, } from "../common-styled-components";
import { withParentDimensions, } from "../generic/withParentDimensions";
import { curveLinear, curveStepAfter, line } from "d3-shape";
import { scaleBand, scaleLinear } from "d3-scale";
import styled from "styled-components";
import { useState } from "react";
import { getDivider, getDividerLabel } from "../../util/amountUtils";
var ChartContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  margin-top: ", ";\n  margin-bottom: ", ";\n  overflow: visible;\n"], ["\n  width: 100%;\n  margin-top: ", ";\n  margin-bottom: ", ";\n  overflow: visible;\n"])), function (p) { return (p.noMargin ? 0 : theme.spacing(4)); }, function (p) { return (p.noMargin ? 0 : theme.spacing(5)); });
var START_AND_END_YEARS = [2025, 2050];
var getTickDigits = function (maxValue) {
    if (maxValue === undefined) {
        return 0;
    }
    if (maxValue < 1) {
        return 2;
    }
    if (maxValue < 10) {
        return 1;
    }
    return 0;
};
var getLocaleNumber = function (digits, n) {
    return n !== undefined
        ? n.toLocaleString("en-US", {
            maximumFractionDigits: digits,
            minimumFractionDigits: digits,
        })
        : undefined;
};
var WIDE_GRAPH_LIMIT = 420;
var MAX_WIDTH = 640;
export default withParentDimensions(function (_a) {
    var title = _a.title, subTitle = _a.subTitle, unit = _a.unit, data = _a.data, complianceData = _a.complianceData, chartType = _a.chartType, forceZero = _a.forceZero, forcedMaxValue = _a.forcedMaxValue, forceDigits = _a.forceDigits, parentDimensions = _a.parentDimensions, errorYearMessage = _a.errorYearMessage, onChange = _a.onChange, noMargin = _a.noMargin, canDivide = _a.canDivide;
    var _b = useState(), hoverYear = _b[0], setHoverYear = _b[1];
    var width = Math.min(parentDimensions.width, MAX_WIDTH);
    var isWide = width > WIDE_GRAPH_LIMIT;
    var heightWithoutTopMargin = 140;
    var fontSize = theme.fontSizeNumber(-1);
    var tickFontSize = theme.fontSizeNumber(-2);
    var topMargin = isWide
        ? Math.max(data.length * (fontSize + 2) + 6, 24)
        : 24;
    var height = heightWithoutTopMargin + topMargin;
    var margins = {
        top: isWide ? Math.max(data.length * (fontSize + 2) + 6, 24) : 24,
        bottom: 24,
        left: 70,
        right: 15,
    };
    var lineData = data.map(function (series) { return (__assign(__assign({}, series), { values: series.values.length && chartType === "stepAfter"
            ? series.values.concat({
                year: series.values[series.values.length - 1].year + 5,
                value: series.values[series.values.length - 1].value,
            })
            : series.values })); });
    var innerPadding = isWide ? 25 : 15;
    var _c = useDeepMemo(function () {
        var _a, _b, _c;
        var allValues = chain(data)
            .flatMap(function (series) { return series.values; })
            .map(function (d) { return d.value; })
            .valueOf();
        var allYears = chain(data)
            .flatMap(function (series) { return series.values; })
            .map(function (d) { return d.year; })
            .uniq()
            .valueOf();
        var maxValue = (_a = forcedMaxValue !== null && forcedMaxValue !== void 0 ? forcedMaxValue : max(allValues)) !== null && _a !== void 0 ? _a : 1000;
        var minValue = (_b = min(allValues)) !== null && _b !== void 0 ? _b : 0;
        var divider = canDivide ? (_c = getDivider(maxValue)) !== null && _c !== void 0 ? _c : 1 : 1;
        return [
            [minValue / divider, maxValue / divider],
            [
                min(allYears) || START_AND_END_YEARS[0],
                max(allYears) || START_AND_END_YEARS[1],
            ],
            allYears,
            divider,
        ];
    }, [data, canDivide, forcedMaxValue]), minMaxValues = _c[0], _minMaxYears = _c[1], allYearsWithData = _c[2], divider = _c[3];
    var digits = forceDigits !== null && forceDigits !== void 0 ? forceDigits : getTickDigits(minMaxValues[1]);
    if (width <= 0) {
        return _jsx(ChartContainer, {});
    }
    var yScale = scaleLinear()
        .range([height - margins.bottom, margins.top])
        .domain([forceZero ? 0 : minMaxValues[0], minMaxValues[1]]);
    var xScale = scaleLinear()
        .range([
        margins.left + innerPadding,
        width - margins.right - innerPadding,
    ])
        .domain(START_AND_END_YEARS);
    var curve = chartType === "stepAfter" ? curveStepAfter : curveLinear;
    var lineGenerator = line()
        .x(function (d) { return Math.min(xScale(d.year), width - margins.right); })
        .y(function (d) { return yScale(d.value / divider); })
        .curve(curve);
    var xTicks = range(START_AND_END_YEARS[0], START_AND_END_YEARS[1] + 1, 1).filter(function (t) { return t % 5 === 0; });
    var bandWidth = xScale(xTicks[1]) - xScale(xTicks[0]);
    var missingYears = complianceData
        ? complianceData.filter(function (d) { return !d.is_compliant; }).map(function (d) { return d.year; })
        : xTicks.filter(function (year) { return !allYearsWithData.find(function (y) { return y === year; }); });
    var missingYearsCenter = (missingYears === null || missingYears === void 0 ? void 0 : missingYears.length)
        ? (Math.max(xScale(missingYears[0]), 0) +
            Math.min(xScale(missingYears[missingYears.length - 1]) + bandWidth, width - margins.right)) /
            2
        : undefined;
    var bandScaleWidth = data.length * 4;
    var bandScale = scaleBand()
        .range([-bandScaleWidth, bandScaleWidth])
        .domain(data.map(function (d) { return d.name; }));
    var hoverRender = function () {
        if (!hoverYear || isWide) {
            return undefined;
        }
        var hoveredDatum = data
            .map(function (s) {
            var datum = s.values.find(function (d) { return d.year === hoverYear; });
            return {
                name: s.name,
                color: s.color,
                value: datum === null || datum === void 0 ? void 0 : datum.value,
            };
        })
            .filter(function (d) { return d.value !== undefined; });
        if (!hoveredDatum.length) {
            return undefined;
        }
        var x = xScale(hoverYear);
        var boxTop = margins.top;
        var numbersLength = Math.max.apply(Math, hoveredDatum.map(function (d) { var _a, _b; return (_b = (_a = getLocaleNumber(digits, d.value)) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0; }));
        var padding = 4;
        var boxWidth = numbersLength * 9 + 2 * padding;
        var left = width - x < boxWidth;
        return (_jsxs("g", { children: [_jsx("rect", { fill: theme.colors.white, stroke: theme.colors.grey(3), x: left ? x - boxWidth - 10 : x + 10, y: margins.top, width: boxWidth, height: hoveredDatum.length * (tickFontSize + 2) + padding * 2 }), hoveredDatum.map(function (d, i) {
                    var circleOffset = bandScale(d.name);
                    return (_jsxs("g", { children: [d.value !== undefined && (_jsx("circle", { cx: x +
                                    (chartType === "bar" && circleOffset !== undefined
                                        ? circleOffset + bandScale.bandwidth() / 2
                                        : 0), cy: yScale(d.value / divider), r: 5, fill: d.color })), _jsx("text", __assign({ x: x + (left ? -14 : 14), y: boxTop + padding + tickFontSize + (tickFontSize + 2) * i, fontSize: tickFontSize, fontWeight: "bold", fill: d.color, textAnchor: left ? "end" : "start" }, { children: getLocaleNumber(digits, d.value !== undefined ? d.value / divider : undefined) }))] }, d.name + i));
                })] }));
    };
    return (_jsxs(ChartContainer, __assign({ noMargin: noMargin }, { children: [title ? _jsx(GraphTitle, { children: title }) : undefined, subTitle ? _jsx(GraphSubTitle, { children: subTitle }) : undefined, _jsxs("svg", __assign({ width: width, height: height, style: { marginTop: theme.spacing(0), overflow: "visible" } }, { children: [_jsx("line", { x1: margins.left, x2: margins.left, y1: 0, y2: height - margins.bottom, stroke: theme.colors.grey(4), strokeWidth: 1 }), unit ? (_jsxs(GraphLabel, __assign({ fontSize: fontSize, x: margins.left - 5, y: fontSize, textAnchor: "end" }, { children: [getDividerLabel(divider), unit] }))) : undefined, _jsx("g", { children: xTicks.map(function (t) {
                            var x = xScale(t);
                            return (_jsxs("g", { children: [_jsx("line", { x1: x, x2: x, y1: margins.top, y2: height - margins.bottom + 4, stroke: theme.colors.grey(4), strokeWidth: 1 }), _jsx("text", __assign({ fontSize: tickFontSize, textAnchor: "middle", x: x, y: height - margins.bottom + 4 + tickFontSize }, { children: t.toFixed(0) }))] }, t));
                        }) }), _jsx("g", { children: yScale.ticks(3).map(function (t) {
                            var y = yScale(t);
                            return (_jsxs("g", { children: [_jsx("line", { x1: margins.left - 2, x2: width - margins.right, y1: y, y2: y, stroke: t === 0 ? theme.colors.grey(1) : theme.colors.grey(4), strokeWidth: 1 }), _jsx("text", __assign({ fontSize: tickFontSize, textAnchor: "end", x: margins.left - 4, y: y + tickFontSize * 0.35 }, { children: getLocaleNumber(digits, t) }))] }, t));
                        }) }), chartType !== "bar" && (_jsxs("g", { children: [lineData.map(function (series) { return (_jsx("g", { children: _jsx("path", { d: lineGenerator(series.values) || "", fill: "none", strokeWidth: 2, stroke: series.color }) }, series.name)); }), data.map(function (series) {
                                return !series.hideCircles && (_jsx("g", { children: series.values.map(function (v) { return (_jsx("circle", { cx: xScale(v.year), cy: yScale(v.value / divider), r: 4, fill: series.color }, v.year)); }) }, series.name));
                            })] })), chartType === "bar" && (_jsx("g", { children: data.map(function (series) {
                            return (_jsx("g", { children: series.values.map(function (bar, i) {
                                    var offset = bandScale(series.name);
                                    var x = xScale(bar.year) +
                                        (offset !== undefined
                                            ? offset + bandScale.bandwidth() / 2
                                            : 0);
                                    return (_jsxs("g", { children: [_jsx("line", { x1: x, x2: x, y1: height - margins.bottom, y2: yScale(bar.value / divider), stroke: series.color, strokeWidth: 3 }), _jsx("circle", { cx: x, cy: yScale(bar.value / divider), r: 4, fill: series.color })] }, "".concat(bar.year, "-").concat(series.name, "-").concat(i)));
                                }) }, series.name));
                        }) })), isWide && (_jsx("g", { children: data.map(function (series, i) {
                            return (_jsx("g", { children: series.values.map(function (v) {
                                    var x = xScale(v.year);
                                    return (_jsx("text", __assign({ x: x, textAnchor: "middle", fill: series.color, y: (i + 1) * fontSize + i * 2, fontSize: fontSize }, { children: getLocaleNumber(digits, v.value / divider) }), v.year));
                                }) }, series.name));
                        }) })), (missingYears === null || missingYears === void 0 ? void 0 : missingYears.length) ? (_jsxs("g", { children: [missingYears.map(function (year) {
                                var x = xScale(year);
                                return (_jsx("rect", { x: xScale(year), y: margins.top, width: x + bandWidth > width - margins.right
                                        ? width - margins.right - x
                                        : bandWidth, height: height - margins.bottom - margins.top, fill: "rgba(0,0,0,0.1)" }, year));
                            }), _jsx("text", __assign({ x: missingYearsCenter, y: margins.top +
                                    (height - margins.bottom - margins.top) / 2 +
                                    theme.fontSizeNumber(-1) / 2, fill: theme.colors.grey(1), textAnchor: "middle", fontSize: theme.fontSizeNumber(-1) }, { children: errorYearMessage }))] })) : undefined, hoverRender(), _jsx("g", { children: bandWidth > 0 &&
                            xTicks.map(function (t) { return (_jsx("rect", { x: xScale(t) - bandWidth / 2, y: 0, width: bandWidth, height: height, fill: "rgba(0,0,0,0)", onMouseEnter: function () {
                                    setHoverYear(t);
                                    if (onChange) {
                                        onChange(t);
                                    }
                                }, onMouseLeave: function () {
                                    setHoverYear(undefined);
                                    if (onChange) {
                                        onChange(undefined);
                                    }
                                } }, t)); }) })] })), _jsx(Legend, { children: data.map(function (series) { return (_jsx(LegendItem, { color: series.color, text: series.name }, series.name)); }) })] })));
});
var templateObject_1;
