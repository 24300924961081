// As per https://bit.dev/speedlo/hooks/use-deep-memo
import { isEqual } from "lodash";
import { useRef } from "react";
/**
 * This hook memoizes a result using deep equality, which is something
 * useMemo() doesn't do out-of-the-box.
 */
export var useDeepMemo = function (memoFn, key) {
    var ref = useRef();
    if (!ref.current || !isEqual(key, ref.current.key)) {
        ref.current = { key: key, value: memoFn() };
    }
    return ref.current.value;
};
