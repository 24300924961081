var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useCallback, useState } from "react";
import { isRootParams } from "../util/isRootParams";
export var useMergedCalculatorState = function (initialState) {
    var _a = useState(initialState), state = _a[0], setState = _a[1];
    var setMergedCalculatorState = useCallback(function (newState, isComparison, fuelIndex) {
        if (fuelIndex === undefined) {
            setState(function (prevState) {
                return __assign(__assign({}, prevState), newState);
            });
        }
        else {
            setState(function (prevState) {
                var _a;
                var _b, _c;
                if (!prevState || !isRootParams(prevState)) {
                    return prevState;
                }
                var prevFuels = isComparison
                    ? (_b = prevState === null || prevState === void 0 ? void 0 : prevState.comparison_fuels) !== null && _b !== void 0 ? _b : []
                    : (_c = prevState.fuels) !== null && _c !== void 0 ? _c : [];
                var fuelsLength = Math.max(prevFuels.length, fuelIndex + 1);
                var newFuels = __spreadArray([], Array(fuelsLength), true).map(function (_u, i) {
                    var _a;
                    return i === fuelIndex
                        ? __assign(__assign({}, ((_a = prevFuels[i]) !== null && _a !== void 0 ? _a : {})), newState) : prevFuels[i];
                });
                var key = isComparison
                    ? "comparison_fuels"
                    : "fuels";
                return __assign(__assign({}, prevState), (_a = {}, _a[key] = newFuels, _a));
            });
        }
    }, []);
    var removeFuel = useCallback(function (comparison, index) {
        setState(function (prevState) {
            if (!prevState ||
                !isRootParams(prevState) ||
                (comparison && !prevState.comparison_fuels) ||
                (!comparison && !prevState.fuels)) {
                return prevState;
            }
            console.info("Removing ".concat(comparison ? "comparison " : "", "fuel"), index);
            return __assign(__assign({}, prevState), { fuels: comparison
                    ? prevState.fuels
                    : prevState.fuels.filter(function (_f, i) { return i !== index; }), comparison_fuels: comparison
                    ? prevState.comparison_fuels.filter(function (_f, i) { return i !== index; })
                    : prevState.comparison_fuels });
        });
    }, []);
    return [state, setMergedCalculatorState, removeFuel];
};
