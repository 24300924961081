var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from "styled-components";
import { breakpoints, theme } from "../../theme";
import { withParentDimensions, } from "../generic/withParentDimensions";
import { FullWidthContainer, Ingress, P, TextContent, ToolTitle, } from "../common-styled-components";
import { Link } from "react-router-dom";
import { routeNames } from "../../util/routes";
var BackLink = styled(Link)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: ", ";\n  ", ";\n  color: ", ";\n  text-decoration-style: none;\n  text-decoration-line: none;\n"], ["\n  margin-bottom: ", ";\n  ", ";\n  color: ", ";\n  text-decoration-style: none;\n  text-decoration-line: none;\n"])), theme.spacing(4), theme.fontBold, theme.colors.bluelink);
var ToolContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: ", ";\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: ", ";\n"])), theme.spacing(5));
var IntroContainer = styled(TextContent)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: ", ";\n"], ["\n  padding: ", ";\n"])), theme.spacing(4));
var BorderedFullWidthContainer = styled(FullWidthContainer)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border-top: 1px solid ", ";\n"], ["\n  border-top: 1px solid ", ";\n"])), theme.colors.grey(4));
var ToolColumns = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  display: grid;\n  grid-template-columns: 320px 1fr;\n"], ["\n  width: 100%;\n  display: grid;\n  grid-template-columns: 320px 1fr;\n"])));
var SelectionsContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  border-", ": 1px solid ", ";\n  padding: ", ";\n  display: flex;\n  flex-direction: column;\n  gap: ", ";\n"], ["\n  width: 100%;\n  border-", ": 1px solid ", ";\n  padding: ", ";\n  display: flex;\n  flex-direction: column;\n  gap: ", ";\n"])), function (p) { return p.border; }, theme.colors.grey(4), theme.spacing(4), theme.spacing(3));
var ResultsContainer = styled.section(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 0 ", ";\n  width: 100%;\n"], ["\n  padding: 0 ", ";\n  width: 100%;\n"])), theme.spacing(4));
var ToolLayout = withParentDimensions(function (_a) {
    var width = _a.parentDimensions.width, name = _a.name, intro = _a.intro, selections = _a.selections, results = _a.results;
    var isNarrowScreen = width < breakpoints.tablet;
    return (_jsxs(ToolContainer, { children: [_jsxs(IntroContainer, { children: [_jsx(BackLink, __assign({ to: routeNames.main }, { children: "\u2039 All tools" })), _jsx(ToolTitle, { children: name }), process.env.NODE_ENV === "development" ? (_jsx(P, __assign({ style: { color: theme.colors.red } }, { children: "Development version" }))) : undefined, _jsx(Ingress, { children: intro })] }), _jsx(BorderedFullWidthContainer, { children: isNarrowScreen ? (_jsxs(_Fragment, { children: [_jsx(SelectionsContainer, __assign({ border: "bottom" }, { children: selections })), _jsx(ResultsContainer, { children: results })] })) : (_jsxs(ToolColumns, { children: [_jsx(SelectionsContainer, __assign({ border: "right" }, { children: selections })), _jsx(ResultsContainer, { children: results })] })) })] }));
});
export default ToolLayout;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
