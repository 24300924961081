var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, PageContent, SmallButton, TH, TitleWithExtraContent, } from "../../common-styled-components";
var tableColumns = [
    {
        key: "fromShip",
        label: "From",
    },
    {
        key: "toShip",
        label: "To",
    },
    {
        key: "amountTonnes",
        label: "Amount tCO2e",
    },
    {
        key: "tools",
        label: "Tools",
    },
];
var getCellContent = function (transaction, key) {
    switch (key) {
        case "tools":
            return (_jsxs("div", { children: [_jsx(SmallButton, { children: "Remove" }), _jsx(SmallButton, { children: "Edit" })] }));
        case "created_at":
            return transaction.created_at.toLocaleString("en-US");
        default:
            return transaction[key];
    }
};
export default (function () {
    var addTransaction = function () {
        window.alert("Add transaction");
    };
    return (_jsxs(PageContent, { children: [_jsx(TitleWithExtraContent, __assign({ titleLevel: "Section", extraContent: _jsx(Button, __assign({ onClick: addTransaction }, { children: "+ Add" })) }, { children: "Transactions" })), _jsxs("table", { children: [_jsx("thead", { children: _jsxs("tr", { children: [tableColumns.map(function (c) { return (_jsx(TH, { children: c.label }, c.key)); }), " "] }) }), _jsx("tbody", {})] })] }));
});
