var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { chain, flatMap, groupBy, last, mapValues, range, sum, uniqBy, values, } from "lodash";
import { getDateFromDayOfYear, getDayOfYear, sortDates } from "../timeUtils";
import { predictEOY } from "../statisticsUtils";
var getFuelKey = function (consumption) {
    return "".concat(consumption.fuel, "-").concat(consumption.engine);
};
var getFuelAndEngine = function (fuelEngine) {
    var split = fuelEngine.split("-");
    return {
        fuel: Number(split[0]),
        engine: Number(split[1]),
    };
};
var addFuels = function (oldAmounts, addedAmounts) {
    return Object.keys(addedAmounts).reduce(function (cumu, fuelEngine) {
        var _a;
        var _b;
        return __assign(__assign({}, cumu), (_a = {}, _a[fuelEngine] = ((_b = cumu[fuelEngine]) !== null && _b !== void 0 ? _b : 0) + addedAmounts[fuelEngine], _a));
    }, oldAmounts);
};
var getFuelSpends = function (reports) {
    return flatMap(reports, function (r) { return r.fuel_consumption; }).reduce(function (result, consumption) {
        var _a;
        var _b;
        var fuelKey = getFuelKey(consumption);
        return __assign(__assign({}, result), (_a = {}, _a[fuelKey] = ((_b = result[fuelKey]) !== null && _b !== void 0 ? _b : 0) + consumption.amountKg, _a));
    }, {});
};
var getAmountPerFuel = function (fuelAmounts, fuel) {
    var values = Object.keys(fuelAmounts)
        .filter(function (key) { return getFuelAndEngine(key).fuel === fuel; })
        .map(function (key) { return fuelAmounts[key]; });
    return values.length === 0 ? 0 : sum(values);
};
var getFuelTonsPrediction = function (data) {
    return data.reduce(function (result, fuel) {
        var _a;
        var _b, _c;
        return __assign(__assign({}, result), (_a = {}, _a[fuel.fuel] = (_c = (_b = predictEOY(fuel.timeline.map(function (d) { return ({ date: d.date, value: d.amountKg }); }), MONTHS)) === null || _b === void 0 ? void 0 : _b.predictionEOY.value) !== null && _c !== void 0 ? _c : 0, _a));
    }, {});
    // const fuels = uniq(
    //   Object.keys(amounts).map((key) => getFuelAndEngine(key).fuel)
    // );
    // return fuels.reduce<FuelAmountsForFuel>(
    //   (result, fuel) => ({
    //     ...result,
    //     [fuel]: getAmountPerFuel(amounts, fuel),
    //   }),
    //   {}
    // );
};
var getFuelSeriesTons = function (timeline) {
    var fuelsAndEngines = flatMap(timeline, function (d) {
        return Object.keys(d.fuels).map(function (key) { return getFuelAndEngine(key); });
    });
    var fuels = uniqBy(fuelsAndEngines, function (fe) { return fe.fuel; }).map(function (f) { return f.fuel; });
    return fuels.map(function (fuel) { return ({
        fuel: fuel,
        timeline: timeline.map(function (d) { return ({
            date: d.date,
            amountKg: getAmountPerFuel(d.fuels, fuel) / 10,
        }); }),
    }); });
};
var getEnergyMJ = function (fuelEngine, amountKg, metadata) {
    var fuel = getFuelAndEngine(fuelEngine).fuel;
    var fuelData = metadata.fuels[fuel];
    return fuelData.energy_content * amountKg;
};
var getEmissionsGrams = function (fuelEngine, energyMJ, metaData) {
    var fuelAndEngine = getFuelAndEngine(fuelEngine);
    var intensity = metaData["intensity-fuel-engine"][fuelAndEngine.fuel][fuelAndEngine.engine];
    return energyMJ * intensity;
};
var getIntensityData = function (fuelAmounts, metaData, carbonLimitYear) {
    var energyAndEmissions = chain(fuelAmounts)
        .mapValues(function (amountKg, fuelEngine) {
        var energyMJ = getEnergyMJ(fuelEngine, amountKg, metaData);
        var emissionsGrams = getEmissionsGrams(fuelEngine, energyMJ, metaData);
        return {
            energyMJ: energyMJ,
            emissionsGrams: emissionsGrams,
        };
    })
        .values()
        .value();
    var totalEnergy = energyAndEmissions.length
        ? sum(energyAndEmissions.map(function (d) { return d.energyMJ; }))
        : 0;
    var totalEmissions = energyAndEmissions.length
        ? sum(energyAndEmissions.map(function (d) { return d.emissionsGrams; }))
        : 0;
    var totalIntensity = totalEnergy !== 0 && totalEmissions !== 0
        ? totalEmissions / totalEnergy
        : undefined;
    var intensityLimit = metaData.intensity[carbonLimitYear];
    var emissionsLimit = intensityLimit * totalEnergy;
    return {
        carbonIntensity: totalIntensity,
        co2eEmissionTons: totalEmissions / 1000000,
        energyMJ: totalEnergy,
        emissionsLimitTons: emissionsLimit / 1000000,
        emissionsBalanceTons: (emissionsLimit - totalEmissions) / 1000000,
    };
};
var MONTHS = 1;
var getFuelPredictions = function (data) {
    var fuelEngineStrings = chain(data)
        .flatMap(function (d) { return Object.keys(d.fuels); })
        .uniq()
        .value();
    var predictions = fuelEngineStrings.reduce(function (result, fuelEngine) {
        var _a;
        var _b;
        var dataForFuelEngine = data
            .map(function (d) { return ({
            date: d.date,
            value: d.fuels[fuelEngine],
        }); })
            .filter(function (d) { return d.date !== undefined && d.value !== undefined; });
        var predictionForFuelEngine = (_b = predictEOY(dataForFuelEngine, MONTHS)) === null || _b === void 0 ? void 0 : _b.predictionEOY;
        if (predictionForFuelEngine) {
            return __assign(__assign({}, result), (_a = {}, _a[fuelEngine] = {
                date: predictionForFuelEngine.date,
                amountKg: predictionForFuelEngine.value,
            }, _a));
        }
        return result;
    }, {});
    return {
        date: values(predictions)[0].date,
        amounts: mapValues(predictions, function (p) { return p.amountKg; }),
    };
};
export var analyzeReports = function (reports, metaData, year, carbonLimitYear) {
    var timeStamps = reports
        .map(function (report) { return report.created_at; })
        .filter(function (date) { return date.getFullYear() === year; });
    timeStamps.sort(sortDates);
    var lastDate = timeStamps[timeStamps.length - 1];
    var lastDayOfYear = getDayOfYear(lastDate);
    var dailyReports = groupBy(reports, function (r) { return getDayOfYear(r.created_at); });
    var fuelSpend = range(1, lastDayOfYear + 1).reduce(function (result, dayOfYear) {
        var _a, _b;
        var date = getDateFromDayOfYear(dayOfYear, year);
        var reportsForDay = dailyReports[dayOfYear.toString()];
        if (reportsForDay === undefined || reportsForDay.length === 0) {
            return __assign(__assign({}, result), { dailyFuels: __spreadArray(__spreadArray([], result.dailyFuels, true), [{ date: date, fuels: {} }], false), cumulativeFuelTimeline: __spreadArray(__spreadArray([], result.cumulativeFuelTimeline, true), [
                    {
                        date: date,
                        fuels: (_b = (_a = last(result.cumulativeFuelTimeline)) === null || _a === void 0 ? void 0 : _a.fuels) !== null && _b !== void 0 ? _b : {},
                    },
                ], false) });
        }
        var fuelsForDay = getFuelSpends(reportsForDay);
        var cumulativeFuels = addFuels(result.cumulativeFuels, fuelsForDay);
        return {
            cumulativeFuels: cumulativeFuels,
            dailyFuels: __spreadArray(__spreadArray([], result.dailyFuels, true), [
                {
                    date: date,
                    fuels: fuelsForDay,
                },
            ], false),
            cumulativeFuelTimeline: __spreadArray(__spreadArray([], result.cumulativeFuelTimeline, true), [
                {
                    date: date,
                    fuels: cumulativeFuels,
                },
            ], false),
        };
    }, {
        cumulativeFuels: {},
        dailyFuels: [],
        cumulativeFuelTimeline: [],
    });
    var fuelPredictions = getFuelPredictions(fuelSpend.cumulativeFuelTimeline);
    var cumulativeFuelTimelineTons = getFuelSeriesTons(fuelSpend.cumulativeFuelTimeline);
    var fuelTonsPrediction = getFuelTonsPrediction(cumulativeFuelTimelineTons);
    var intensityPrediction = getIntensityData(fuelPredictions.amounts, metaData, carbonLimitYear);
    var intensities = {
        total: getIntensityData(fuelSpend.cumulativeFuels, metaData, carbonLimitYear),
        daily: fuelSpend.dailyFuels.map(function (d) { return ({
            date: d.date,
            intensity: getIntensityData(d.fuels, metaData, carbonLimitYear),
        }); }),
        cumulative: fuelSpend.cumulativeFuelTimeline.map(function (d) { return ({
            date: d.date,
            intensity: getIntensityData(d.fuels, metaData, carbonLimitYear),
        }); }),
    };
    return {
        fuelSpend: {
            cumulativeFuels: fuelSpend.cumulativeFuels,
            dailyFuelsTons: getFuelSeriesTons(fuelSpend.dailyFuels),
            cumulativeFuelTimelineTons: cumulativeFuelTimelineTons,
        },
        intensities: intensities,
        predictions: {
            date: fuelPredictions.date,
            fuels: fuelTonsPrediction,
            intensities: intensityPrediction,
        },
    };
};
