var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { theme } from "../../theme";
import { Selector, SelectorLabel } from "../common-styled-components";
import Select from "react-select";
import { NumberInput } from "../generic/NumberInput";
import { isAlternativeFuelParams, } from "../fleet-calculator/calculator-types";
var selectStyle = {
    control: function (baseStyles, _state) { return (__assign(__assign({}, baseStyles), { borderColor: theme.colors.grey(3) })); },
};
export var EngineSelector = function (_a) {
    var isComparison = _a.isComparison, texts = _a.texts, metadata = _a.metadata, params = _a.params, editable = _a.editable, setParamsHelper = _a.setParamsHelper, engineOptions = _a.engineOptions, selectedEngineOption = _a.selectedEngineOption, onChangeEngineType = _a.onChangeEngineType;
    var fuelMetadata = params.fuel_type
        ? metadata.fuels[params.fuel_type.toString()]
        : undefined;
    return (_jsxs("div", { children: [_jsxs(Selector, { children: [_jsx(SelectorLabel, __assign({ disabled: !editable }, { children: isComparison ? texts.engine_type_for_comparison : texts.engine_type })), _jsx(Select, { isDisabled: !editable, options: engineOptions, onChange: function (newValue) { return onChangeEngineType((newValue === null || newValue === void 0 ? void 0 : newValue.value) || "1"); }, value: selectedEngineOption, isOptionSelected: function (option) {
                            return params.engine_type !== undefined
                                ? option.value === params.engine_type.toString()
                                : false;
                        }, styles: selectStyle })] }), isComparison && params.fuel_type !== undefined && fuelMetadata && (_jsxs(Selector, { children: [_jsx(SelectorLabel, __assign({ disabled: !editable }, { children: "Carbon intensity (gCO\u2082e/MJ)" })), _jsx(NumberInput, { disabled: !editable ||
                            params.fuel_type === undefined ||
                            metadata.fuels[params.fuel_type.toString()].is_fixed_intensity, value: isAlternativeFuelParams(params)
                            ? params.intensity
                            : fuelMetadata.average_intensity, onChange: function (value) { return setParamsHelper({ intensity: value }); }, step: 0.1, isFloat: true }), params.fuel_type !== undefined &&
                        fuelMetadata.is_fixed_intensity && (_jsx("div", { children: "This fuel's intensity is fixed in the FuelEU Maritime regulation and cannot be changed." }))] })), _jsxs(Selector, { children: [_jsx(SelectorLabel, __assign({ disabled: !editable }, { children: texts.engine_efficiency_as_percentage })), _jsx(NumberInput, { disabled: !editable, value: params.engine_efficiency, onChange: function (value) {
                            return setParamsHelper({ engine_efficiency: value });
                        }, isFloat: true })] })] }));
};
