export var getCarbonIntensity = function (intensity, limits) {
    var _a, _b;
    if (intensity < ((_a = limits === null || limits === void 0 ? void 0 : limits.low) !== null && _a !== void 0 ? _a : 25)) {
        return "low";
    }
    if (intensity < ((_b = limits === null || limits === void 0 ? void 0 : limits.medium) !== null && _b !== void 0 ? _b : 70)) {
        return "medium";
    }
    return "high";
};
