var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { scaleLinear, scaleTime } from "d3-scale";
import { curveLinear, line } from "d3-shape";
import { withParentDimensions, } from "../generic/withParentDimensions";
import { useDeepMemo } from "../../hooks/useDeepMemo";
import { flatMap } from "lodash";
import { GraphTitle, Legend, LegendItem } from "../common-styled-components";
import styled from "styled-components";
import { theme } from "../../theme";
import { getDivider, getDividerLabel } from "../../util/amountUtils";
var LimitLine = styled.line(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  stroke-dasharray: 5 2;\n  stroke-width: 1px;\n  fill: none;\n"], ["\n  stroke-dasharray: 5 2;\n  stroke-width: 1px;\n  fill: none;\n"])));
export default withParentDimensions(function (_a) {
    var data = _a.data, limits = _a.limits, title = _a.title, unit = _a.unit, width = _a.parentDimensions.width;
    var HEIGHT = 200;
    var MARGINS = {
        top: 18,
        bottom: 20,
        left: 60,
        right: 25,
    };
    var CONTENT_BOTTOM = HEIGHT - MARGINS.bottom;
    var TICK_FONT_SIZE = 12;
    var _b = useDeepMemo(function () {
        var _a, _b;
        var allDateValues = flatMap(data, function (d) { return d.data; }).concat(data.map(function (d) { return d.prediction; }).filter(function (d) { return d; }));
        var timeValues = allDateValues.map(function (d) { return d.date.getTime(); });
        var minTime = Math.min.apply(Math, timeValues);
        var maxTime = Math.max.apply(Math, timeValues);
        var scaleX = scaleTime()
            .range([MARGINS.left, width - MARGINS.right])
            .domain([new Date(minTime), new Date(maxTime)]);
        var values = allDateValues
            .map(function (d) { return d.value; })
            .filter(function (d) { return d !== undefined && !isNaN(d); });
        var limitValues = (_a = limits === null || limits === void 0 ? void 0 : limits.map(function (l) { return l.value; })) !== null && _a !== void 0 ? _a : [];
        var allValues = values.concat(limitValues);
        var minY = Math.min.apply(Math, allValues);
        var maxY = Math.max.apply(Math, allValues);
        var divider = (_b = getDivider(maxY)) !== null && _b !== void 0 ? _b : 1;
        var scaleY = scaleLinear()
            .range([CONTENT_BOTTOM, MARGINS.top])
            .domain([minY, maxY])
            .nice();
        var lineGenerator = line()
            .x(function (d) { return scaleX(d.date); })
            .defined(function (d) { return d !== undefined && !isNaN(d.value); })
            .y(function (d) { return (d.value !== undefined ? scaleY(d.value) : 0); })
            .curve(curveLinear);
        return [scaleX, scaleY, lineGenerator, divider];
    }, [data, limits, width]), scaleX = _b[0], scaleY = _b[1], lineGenerator = _b[2], divider = _b[3];
    var zeroLine = scaleY(0);
    var zeroLineInBounds = zeroLine >= MARGINS.top && zeroLine <= HEIGHT - MARGINS.bottom;
    return (_jsxs("div", { children: [_jsx(GraphTitle, { children: title }), _jsxs("svg", __assign({ width: width, height: HEIGHT }, { children: [scaleX.ticks(Math.round(width / 120)).map(function (t) {
                        var tickX = scaleX(t);
                        var tickLength = 6;
                        return (_jsxs("g", { children: [_jsx("line", { x1: tickX, x2: tickX, y1: CONTENT_BOTTOM, y2: CONTENT_BOTTOM + tickLength, strokeWidth: 1, stroke: "#aaa" }), _jsx("text", __assign({ x: tickX, textAnchor: "middle", y: CONTENT_BOTTOM + tickLength + TICK_FONT_SIZE, fontSize: TICK_FONT_SIZE }, { children: t.toLocaleDateString("fi-FI", {
                                        day: "numeric",
                                        month: "numeric",
                                    }) }))] }, t.toString()));
                    }), scaleY.ticks(4).map(function (t) {
                        var tickY = scaleY(t);
                        var tickLength = 6;
                        return (_jsxs("g", { children: [_jsx("line", { x1: width - MARGINS.right, x2: MARGINS.left - tickLength, y1: tickY, y2: tickY, strokeWidth: 1, stroke: theme.colors.grey(4), className: "stroke-gray-200" }), _jsxs("text", __assign({ x: MARGINS.left - tickLength, textAnchor: "end", y: tickY + TICK_FONT_SIZE / 2 - 2, fontSize: TICK_FONT_SIZE }, { children: [(t / divider).toLocaleString("en-US"), getDividerLabel(divider)] }))] }, t.toString()));
                    }), zeroLineInBounds && (_jsx("line", { strokeWidth: 1, stroke: "#aaa", x1: MARGINS.left, x2: width - MARGINS.right, y1: zeroLine, y2: zeroLine })), _jsx("line", { strokeWidth: 1, stroke: "#aaa", x1: MARGINS.left, x2: MARGINS.left, y1: CONTENT_BOTTOM, y2: 0 }), _jsx("text", __assign({ x: MARGINS.left + 4, y: MARGINS.top - 3, fontSize: TICK_FONT_SIZE }, { children: unit })), limits === null || limits === void 0 ? void 0 : limits.map(function (limit) { return (_jsx(LimitLine, { x1: MARGINS.left, x2: width - MARGINS.right, y1: scaleY(limit.value), y2: scaleY(limit.value), stroke: limit.color }, limit.label)); }), data.map(function (series) {
                        var _a;
                        var lastInSeries = series.data[series.data.length - 1];
                        return (_jsxs("g", { children: [_jsx("path", { d: (_a = lineGenerator(series.data)) !== null && _a !== void 0 ? _a : "", fill: "none", stroke: series.color, strokeWidth: 1.5, strokeDasharray: series.dashed ? "3 2" : undefined, strokeLinejoin: "round", strokeLinecap: "round" }), series.prediction &&
                                    series.data.length > 0 &&
                                    lastInSeries.value !== undefined && (_jsx("line", { fill: "none", stroke: series.color, strokeDasharray: "2 2", strokeWidth: 1.5, opacity: 0.7, x1: scaleX(lastInSeries.date), x2: scaleX(series.prediction.date), y1: scaleY(lastInSeries.value), y2: scaleY(series.prediction.value) }))] }, series.name));
                    })] })), _jsxs(Legend, { children: [data.map(function (series) { return (_jsx(LegendItem, { color: series.color, text: series.name }, series.name)); }), limits === null || limits === void 0 ? void 0 : limits.map(function (limit) { return (_jsx(LegendItem, { color: limit.color, text: limit.label }, limit.label)); })] })] }));
});
var templateObject_1;
