var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var getReports = function (reports, ship) {
    return reports.filter(function (r) { return r.ship === ship; });
};
var simulated_reports = [
    {
        created_at: new Date("2025-01-26T00:51:00"),
        ship: "9857728",
        journey_ended_at: new Date("2025-01-26T00:51:00"),
        journey_started_at: new Date("2025-01-01T00:00:00"),
        start_port: "Singapore",
        end_port: "Busan",
        distance: 12017,
        fuel_consumption: [
            {
                fuel: 1,
                engine: 1,
                amountKg: 128756,
            },
        ],
    },
    {
        created_at: new Date("2025-02-03T02:00:00"),
        ship: "9857728",
        journey_ended_at: new Date("2025-02-03T02:00:00"),
        journey_started_at: new Date("2025-01-08T00:00:00"),
        start_port: "Port of Kaohsiung",
        end_port: "Port of Felixstowe",
        distance: 12520,
        fuel_consumption: [
            {
                fuel: 1,
                engine: 1,
                amountKg: 205082,
            },
        ],
    },
    {
        created_at: new Date("2025-02-03T00:57:00"),
        ship: "9857728",
        journey_ended_at: new Date("2025-02-03T00:57:00"),
        journey_started_at: new Date("2025-01-31T00:00:00"),
        start_port: "Shanghai",
        end_port: "Hamburg",
        distance: 1459,
        fuel_consumption: [
            {
                fuel: 1,
                engine: 1,
                amountKg: 17417,
            },
        ],
    },
    {
        created_at: new Date("2025-02-24T16:51:00"),
        ship: "9857728",
        journey_ended_at: new Date("2025-02-24T16:51:00"),
        journey_started_at: new Date("2025-01-27T00:00:00"),
        start_port: "Shanghai",
        end_port: "Port of Los Angeles",
        distance: 13777,
        fuel_consumption: [
            {
                fuel: 1,
                engine: 1,
                amountKg: 263468,
            },
        ],
    },
    {
        created_at: new Date("2025-02-09T02:18:00"),
        ship: "9857728",
        journey_ended_at: new Date("2025-02-09T02:18:00"),
        journey_started_at: new Date("2025-01-11T00:00:00"),
        start_port: "Hamburg",
        end_port: "Jebel Ali",
        distance: 13966,
        fuel_consumption: [
            {
                fuel: 2,
                engine: 1,
                amountKg: 263442,
            },
        ],
    },
    {
        created_at: new Date("2025-01-28T17:33:00"),
        ship: "9257140",
        journey_ended_at: new Date("2025-01-28T17:33:00"),
        journey_started_at: new Date("2025-01-20T00:00:00"),
        start_port: "Rotterdam",
        end_port: "Hamburg",
        distance: 4191,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 87284,
            },
        ],
    },
    {
        created_at: new Date("2025-01-17T13:57:00"),
        ship: "9257140",
        journey_ended_at: new Date("2025-01-17T13:57:00"),
        journey_started_at: new Date("2025-01-01T00:00:00"),
        start_port: "Port of Hong Kong",
        end_port: "Antwerp",
        distance: 7959,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 220477,
            },
        ],
    },
    {
        created_at: new Date("2025-02-11T14:18:00"),
        ship: "9257140",
        journey_ended_at: new Date("2025-02-11T14:18:00"),
        journey_started_at: new Date("2025-01-22T00:00:00"),
        start_port: "Port of Los Angeles",
        end_port: "Antwerp",
        distance: 9886,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 264858,
            },
        ],
    },
    {
        created_at: new Date("2025-02-06T18:24:00"),
        ship: "9257140",
        journey_ended_at: new Date("2025-02-06T18:24:00"),
        journey_started_at: new Date("2025-01-17T00:00:00"),
        start_port: "Port of Tanjung Pelepas",
        end_port: "Port of Hong Kong",
        distance: 9968,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 243047,
            },
        ],
    },
    {
        created_at: new Date("2025-01-28T20:39:00"),
        ship: "9257140",
        journey_ended_at: new Date("2025-01-28T20:39:00"),
        journey_started_at: new Date("2025-01-14T00:00:00"),
        start_port: "Panama Canal",
        end_port: "Busan",
        distance: 7133,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 184932,
            },
        ],
    },
    {
        created_at: new Date("2025-01-27T09:30:00"),
        ship: "9959339",
        journey_ended_at: new Date("2025-01-27T09:30:00"),
        journey_started_at: new Date("2025-01-01T00:00:00"),
        start_port: "Rotterdam",
        end_port: "Port of Tanjung Pelepas",
        distance: 12670,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 294760,
            },
        ],
    },
    {
        created_at: new Date("2025-02-07T23:54:00"),
        ship: "9959339",
        journey_ended_at: new Date("2025-02-07T23:54:00"),
        journey_started_at: new Date("2025-01-11T00:00:00"),
        start_port: "Singapore",
        end_port: "Port of Long Beach",
        distance: 13438,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 382441,
            },
        ],
    },
    {
        created_at: new Date("2025-02-18T04:21:00"),
        ship: "9959339",
        journey_ended_at: new Date("2025-02-18T04:21:00"),
        journey_started_at: new Date("2025-01-20T00:00:00"),
        start_port: "Hamburg",
        end_port: "Port of Kaohsiung",
        distance: 14007,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 360860,
            },
        ],
    },
    {
        created_at: new Date("2025-01-27T02:45:00"),
        ship: "9959339",
        journey_ended_at: new Date("2025-01-27T02:45:00"),
        journey_started_at: new Date("2025-01-12T00:00:00"),
        start_port: "Port of Felixstowe",
        end_port: "Port of Hong Kong",
        distance: 7255,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 169989,
            },
        ],
    },
    {
        created_at: new Date("2025-02-01T19:18:00"),
        ship: "9959339",
        journey_ended_at: new Date("2025-02-01T19:18:00"),
        journey_started_at: new Date("2025-01-11T00:00:00"),
        start_port: "Panama Canal",
        end_port: "Port of Tanjung Pelepas",
        distance: 10466,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 310503,
            },
        ],
    },
    {
        created_at: new Date("2025-02-06T19:18:00"),
        ship: "9444520",
        journey_ended_at: new Date("2025-02-06T19:18:00"),
        journey_started_at: new Date("2025-01-13T00:00:00"),
        start_port: "Port of Long Beach",
        end_port: "Jebel Ali",
        distance: 11906,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 255680,
            },
        ],
    },
    {
        created_at: new Date("2025-02-03T23:42:00"),
        ship: "9444520",
        journey_ended_at: new Date("2025-02-03T23:42:00"),
        journey_started_at: new Date("2025-01-15T00:00:00"),
        start_port: "Hamburg",
        end_port: "Suez Canal",
        distance: 9594,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 213428,
            },
        ],
    },
    {
        created_at: new Date("2025-02-15T18:45:00"),
        ship: "9444520",
        journey_ended_at: new Date("2025-02-15T18:45:00"),
        journey_started_at: new Date("2025-01-20T00:00:00"),
        start_port: "Panama Canal",
        end_port: "Rotterdam",
        distance: 12855,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 342597,
            },
        ],
    },
    {
        created_at: new Date("2025-01-23T07:06:00"),
        ship: "9444520",
        journey_ended_at: new Date("2025-01-23T07:06:00"),
        journey_started_at: new Date("2025-01-06T00:00:00"),
        start_port: "Jebel Ali",
        end_port: "Port of Los Angeles",
        distance: 8302,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 246808,
            },
        ],
    },
    {
        created_at: new Date("2025-02-04T10:48:00"),
        ship: "9444520",
        journey_ended_at: new Date("2025-02-04T10:48:00"),
        journey_started_at: new Date("2025-01-19T00:00:00"),
        start_port: "Panama Canal",
        end_port: "Port of Tanjung Pelepas",
        distance: 7896,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 186806,
            },
        ],
    },
    {
        created_at: new Date("2025-01-27T19:06:00"),
        ship: "9314848",
        journey_ended_at: new Date("2025-01-27T19:06:00"),
        journey_started_at: new Date("2025-01-23T00:00:00"),
        start_port: "Rotterdam",
        end_port: "Shanghai",
        distance: 2302,
        fuel_consumption: [
            {
                fuel: 2,
                engine: 1,
                amountKg: 26530,
            },
        ],
    },
    {
        created_at: new Date("2025-02-03T13:27:00"),
        ship: "9314848",
        journey_ended_at: new Date("2025-02-03T13:27:00"),
        journey_started_at: new Date("2025-01-20T00:00:00"),
        start_port: "Busan",
        end_port: "Port of Los Angeles",
        distance: 6989,
        fuel_consumption: [
            {
                fuel: 2,
                engine: 1,
                amountKg: 118489,
            },
        ],
    },
    {
        created_at: new Date("2025-02-05T06:15:00"),
        ship: "9314848",
        journey_ended_at: new Date("2025-02-05T06:15:00"),
        journey_started_at: new Date("2025-01-26T00:00:00"),
        start_port: "Port of Kaohsiung",
        end_port: "Port of Long Beach",
        distance: 4925,
        fuel_consumption: [
            {
                fuel: 2,
                engine: 1,
                amountKg: 76294,
            },
        ],
    },
    {
        created_at: new Date("2025-02-06T15:30:00"),
        ship: "9314848",
        journey_ended_at: new Date("2025-02-06T15:30:00"),
        journey_started_at: new Date("2025-01-21T00:00:00"),
        start_port: "Port of Felixstowe",
        end_port: "Port of Long Beach",
        distance: 7990,
        fuel_consumption: [
            {
                fuel: 1,
                engine: 1,
                amountKg: 123723,
            },
        ],
    },
    {
        created_at: new Date("2025-01-24T09:24:00"),
        ship: "9314848",
        journey_ended_at: new Date("2025-01-24T09:24:00"),
        journey_started_at: new Date("2025-01-19T00:00:00"),
        start_port: "Port of Felixstowe",
        end_port: "Shanghai",
        distance: 2588,
        fuel_consumption: [
            {
                fuel: 2,
                engine: 1,
                amountKg: 43494,
            },
        ],
    },
    {
        created_at: new Date("2025-02-14T12:45:00"),
        ship: "9247185",
        journey_ended_at: new Date("2025-02-14T12:45:00"),
        journey_started_at: new Date("2025-01-15T00:00:00"),
        start_port: "Panama Canal",
        end_port: "Port of Kaohsiung",
        distance: 14655,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 400478,
            },
        ],
    },
    {
        created_at: new Date("2025-01-29T07:00:00"),
        ship: "9247185",
        journey_ended_at: new Date("2025-01-29T07:00:00"),
        journey_started_at: new Date("2025-01-16T00:00:00"),
        start_port: "Singapore",
        end_port: "Hamburg",
        distance: 6380,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 184860,
            },
        ],
    },
    {
        created_at: new Date("2025-02-19T15:39:00"),
        ship: "9247185",
        journey_ended_at: new Date("2025-02-19T15:39:00"),
        journey_started_at: new Date("2025-01-31T00:00:00"),
        start_port: "Hamburg",
        end_port: "Port of Tanjung Pelepas",
        distance: 9433,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 191495,
            },
        ],
    },
    {
        created_at: new Date("2025-01-25T10:57:00"),
        ship: "9247185",
        journey_ended_at: new Date("2025-01-25T10:57:00"),
        journey_started_at: new Date("2025-01-21T00:00:00"),
        start_port: "Port of Long Beach",
        end_port: "Port of Kaohsiung",
        distance: 2139,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 62886,
            },
        ],
    },
    {
        created_at: new Date("2025-01-31T07:00:00"),
        ship: "9247185",
        journey_ended_at: new Date("2025-01-31T07:00:00"),
        journey_started_at: new Date("2025-01-30T00:00:00"),
        start_port: "Hamburg",
        end_port: "Port of Kaohsiung",
        distance: 620,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 17998,
            },
        ],
    },
    {
        created_at: new Date("2025-01-17T07:30:00"),
        ship: "9492315",
        journey_ended_at: new Date("2025-01-17T07:30:00"),
        journey_started_at: new Date("2025-01-11T00:00:00"),
        start_port: "Port of Kaohsiung",
        end_port: "Singapore",
        distance: 3030,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 77337,
            },
        ],
    },
    {
        created_at: new Date("2025-02-09T02:30:00"),
        ship: "9492315",
        journey_ended_at: new Date("2025-02-09T02:30:00"),
        journey_started_at: new Date("2025-01-17T00:00:00"),
        start_port: "Hamburg",
        end_port: "Jebel Ali",
        distance: 11090,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 266711,
            },
        ],
    },
    {
        created_at: new Date("2025-01-08T16:18:00"),
        ship: "9492315",
        journey_ended_at: new Date("2025-01-08T16:18:00"),
        journey_started_at: new Date("2025-01-02T00:00:00"),
        start_port: "Hamburg",
        end_port: "Singapore",
        distance: 3206,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 91724,
            },
        ],
    },
    {
        created_at: new Date("2025-01-07T15:12:00"),
        ship: "9492315",
        journey_ended_at: new Date("2025-01-07T15:12:00"),
        journey_started_at: new Date("2025-01-05T00:00:00"),
        start_port: "Antwerp",
        end_port: "Suez Canal",
        distance: 1264,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 34843,
            },
        ],
    },
    {
        created_at: new Date("2025-02-02T12:39:00"),
        ship: "9492315",
        journey_ended_at: new Date("2025-02-02T12:39:00"),
        journey_started_at: new Date("2025-01-14T00:00:00"),
        start_port: "Suez Canal",
        end_port: "Antwerp",
        distance: 9373,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 214487,
            },
        ],
    },
    {
        created_at: new Date("2025-01-12T12:30:00"),
        ship: "9357707",
        journey_ended_at: new Date("2025-01-12T12:30:00"),
        journey_started_at: new Date("2025-01-06T00:00:00"),
        start_port: "Busan",
        end_port: "Port of Tanjung Pelepas",
        distance: 3130,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 62813,
            },
        ],
    },
    {
        created_at: new Date("2025-02-14T02:51:00"),
        ship: "9357707",
        journey_ended_at: new Date("2025-02-14T02:51:00"),
        journey_started_at: new Date("2025-01-22T00:00:00"),
        start_port: "Jebel Ali",
        end_port: "Port of Hong Kong",
        distance: 11097,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 296271,
            },
        ],
    },
    {
        created_at: new Date("2025-02-18T20:33:00"),
        ship: "9357707",
        journey_ended_at: new Date("2025-02-18T20:33:00"),
        journey_started_at: new Date("2025-01-26T00:00:00"),
        start_port: "Hamburg",
        end_port: "Jebel Ali",
        distance: 11451,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 335472,
            },
        ],
    },
    {
        created_at: new Date("2025-01-17T13:30:00"),
        ship: "9357707",
        journey_ended_at: new Date("2025-01-17T13:30:00"),
        journey_started_at: new Date("2025-01-01T00:00:00"),
        start_port: "Port of Kaohsiung",
        end_port: "Hamburg",
        distance: 7950,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 195336,
            },
        ],
    },
    {
        created_at: new Date("2025-01-19T22:00:00"),
        ship: "9357707",
        journey_ended_at: new Date("2025-01-19T22:00:00"),
        journey_started_at: new Date("2025-01-06T00:00:00"),
        start_port: "Port of Kaohsiung",
        end_port: "Hamburg",
        distance: 6680,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 184147,
            },
        ],
    },
    {
        created_at: new Date("2025-01-24T03:39:00"),
        ship: "9023455",
        journey_ended_at: new Date("2025-01-24T03:39:00"),
        journey_started_at: new Date("2025-01-19T00:00:00"),
        start_port: "Shanghai",
        end_port: "Port of Los Angeles",
        distance: 2473,
        fuel_consumption: [
            {
                fuel: 2,
                engine: 1,
                amountKg: 25749,
            },
        ],
    },
    {
        created_at: new Date("2025-02-20T01:36:00"),
        ship: "9023455",
        journey_ended_at: new Date("2025-02-20T01:36:00"),
        journey_started_at: new Date("2025-01-31T00:00:00"),
        start_port: "Busan",
        end_port: "Hamburg",
        distance: 9632,
        fuel_consumption: [
            {
                fuel: 1,
                engine: 1,
                amountKg: 102508,
            },
        ],
    },
    {
        created_at: new Date("2025-02-05T18:54:00"),
        ship: "9023455",
        journey_ended_at: new Date("2025-02-05T18:54:00"),
        journey_started_at: new Date("2025-01-10T00:00:00"),
        start_port: "Antwerp",
        end_port: "Port of Los Angeles",
        distance: 12858,
        fuel_consumption: [
            {
                fuel: 2,
                engine: 1,
                amountKg: 223168,
            },
        ],
    },
    {
        created_at: new Date("2025-01-25T20:45:00"),
        ship: "9023455",
        journey_ended_at: new Date("2025-01-25T20:45:00"),
        journey_started_at: new Date("2025-01-05T00:00:00"),
        start_port: "Antwerp",
        end_port: "Hamburg",
        distance: 10015,
        fuel_consumption: [
            {
                fuel: 1,
                engine: 1,
                amountKg: 118189,
            },
        ],
    },
    {
        created_at: new Date("2025-02-05T06:39:00"),
        ship: "9023455",
        journey_ended_at: new Date("2025-02-05T06:39:00"),
        journey_started_at: new Date("2025-01-23T00:00:00"),
        start_port: "Jebel Ali",
        end_port: "Busan",
        distance: 6373,
        fuel_consumption: [
            {
                fuel: 2,
                engine: 1,
                amountKg: 122748,
            },
        ],
    },
    {
        created_at: new Date("2025-01-17T12:48:00"),
        ship: "9267322",
        journey_ended_at: new Date("2025-01-17T12:48:00"),
        journey_started_at: new Date("2025-01-05T00:00:00"),
        start_port: "Port of Long Beach",
        end_port: "Port of Tanjung Pelepas",
        distance: 6016,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 177706,
            },
        ],
    },
    {
        created_at: new Date("2025-01-16T04:21:00"),
        ship: "9267322",
        journey_ended_at: new Date("2025-01-16T04:21:00"),
        journey_started_at: new Date("2025-01-06T00:00:00"),
        start_port: "Hamburg",
        end_port: "Port of Long Beach",
        distance: 4887,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 99390,
            },
        ],
    },
    {
        created_at: new Date("2025-01-05T00:57:00"),
        ship: "9267322",
        journey_ended_at: new Date("2025-01-05T00:57:00"),
        journey_started_at: new Date("2025-01-01T00:00:00"),
        start_port: "Port of Felixstowe",
        end_port: "Hamburg",
        distance: 1939,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 56243,
            },
        ],
    },
    {
        created_at: new Date("2025-01-16T10:21:00"),
        ship: "9267322",
        journey_ended_at: new Date("2025-01-16T10:21:00"),
        journey_started_at: new Date("2025-01-10T00:00:00"),
        start_port: "Suez Canal",
        end_port: "Port of Hong Kong",
        distance: 3087,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 79460,
            },
        ],
    },
    {
        created_at: new Date("2025-01-15T05:06:00"),
        ship: "9267322",
        journey_ended_at: new Date("2025-01-15T05:06:00"),
        journey_started_at: new Date("2025-01-05T00:00:00"),
        start_port: "Port of Kaohsiung",
        end_port: "Port of Los Angeles",
        distance: 4902,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 145289,
            },
        ],
    },
    {
        created_at: new Date("2025-02-21T11:42:00"),
        ship: "9848910",
        journey_ended_at: new Date("2025-02-21T11:42:00"),
        journey_started_at: new Date("2025-01-29T00:00:00"),
        start_port: "Panama Canal",
        end_port: "Port of Felixstowe",
        distance: 11274,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 282464,
            },
        ],
    },
    {
        created_at: new Date("2025-02-21T04:03:00"),
        ship: "9848910",
        journey_ended_at: new Date("2025-02-21T04:03:00"),
        journey_started_at: new Date("2025-01-23T00:00:00"),
        start_port: "Hamburg",
        end_port: "Suez Canal",
        distance: 14001,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 329244,
            },
        ],
    },
    {
        created_at: new Date("2025-01-31T18:15:00"),
        ship: "9848910",
        journey_ended_at: new Date("2025-01-31T18:15:00"),
        journey_started_at: new Date("2025-01-06T00:00:00"),
        start_port: "Port of Los Angeles",
        end_port: "Busan",
        distance: 12365,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 359569,
            },
        ],
    },
    {
        created_at: new Date("2025-02-24T19:27:00"),
        ship: "9848910",
        journey_ended_at: new Date("2025-02-24T19:27:00"),
        journey_started_at: new Date("2025-01-28T00:00:00"),
        start_port: "Busan",
        end_port: "Panama Canal",
        distance: 13349,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 298077,
            },
        ],
    },
    {
        created_at: new Date("2025-01-25T12:48:00"),
        ship: "9848910",
        journey_ended_at: new Date("2025-01-25T12:48:00"),
        journey_started_at: new Date("2025-01-21T00:00:00"),
        start_port: "Port of Hong Kong",
        end_port: "Antwerp",
        distance: 2176,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 45485,
            },
        ],
    },
    {
        created_at: new Date("2025-01-18T09:45:00"),
        ship: "9056034",
        journey_ended_at: new Date("2025-01-18T09:45:00"),
        journey_started_at: new Date("2025-01-14T00:00:00"),
        start_port: "Port of Hong Kong",
        end_port: "Hamburg",
        distance: 2115,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 59874,
            },
        ],
    },
    {
        created_at: new Date("2025-01-11T14:27:00"),
        ship: "9056034",
        journey_ended_at: new Date("2025-01-11T14:27:00"),
        journey_started_at: new Date("2025-01-06T00:00:00"),
        start_port: "Port of Tanjung Pelepas",
        end_port: "Port of Hong Kong",
        distance: 2689,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 61943,
            },
        ],
    },
    {
        created_at: new Date("2025-01-17T15:03:00"),
        ship: "9056034",
        journey_ended_at: new Date("2025-01-17T15:03:00"),
        journey_started_at: new Date("2025-01-01T00:00:00"),
        start_port: "Antwerp",
        end_port: "Port of Tanjung Pelepas",
        distance: 7981,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 181516,
            },
        ],
    },
    {
        created_at: new Date("2025-02-20T06:54:00"),
        ship: "9056034",
        journey_ended_at: new Date("2025-02-20T06:54:00"),
        journey_started_at: new Date("2025-01-27T00:00:00"),
        start_port: "Jebel Ali",
        end_port: "Port of Hong Kong",
        distance: 11658,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 316051,
            },
        ],
    },
    {
        created_at: new Date("2025-01-24T21:48:00"),
        ship: "9056034",
        journey_ended_at: new Date("2025-01-24T21:48:00"),
        journey_started_at: new Date("2025-01-23T00:00:00"),
        start_port: "Rotterdam",
        end_port: "Port of Long Beach",
        distance: 916,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 24728,
            },
        ],
    },
    {
        created_at: new Date("2025-02-22T05:21:00"),
        ship: "9344595",
        journey_ended_at: new Date("2025-02-22T05:21:00"),
        journey_started_at: new Date("2025-01-29T00:00:00"),
        start_port: "Shanghai",
        end_port: "Jebel Ali",
        distance: 11627,
        fuel_consumption: [
            {
                fuel: 2,
                engine: 1,
                amountKg: 128043,
            },
        ],
    },
    {
        created_at: new Date("2025-01-25T06:15:00"),
        ship: "9344595",
        journey_ended_at: new Date("2025-01-25T06:15:00"),
        journey_started_at: new Date("2025-01-22T00:00:00"),
        start_port: "Panama Canal",
        end_port: "Port of Hong Kong",
        distance: 1565,
        fuel_consumption: [
            {
                fuel: 2,
                engine: 1,
                amountKg: 29637,
            },
        ],
    },
    {
        created_at: new Date("2025-02-13T11:48:00"),
        ship: "9344595",
        journey_ended_at: new Date("2025-02-13T11:48:00"),
        journey_started_at: new Date("2025-01-31T00:00:00"),
        start_port: "Singapore",
        end_port: "Suez Canal",
        distance: 6476,
        fuel_consumption: [
            {
                fuel: 1,
                engine: 1,
                amountKg: 120956,
            },
        ],
    },
    {
        created_at: new Date("2025-02-02T06:21:00"),
        ship: "9344595",
        journey_ended_at: new Date("2025-02-02T06:21:00"),
        journey_started_at: new Date("2025-01-12T00:00:00"),
        start_port: "Port of Tanjung Pelepas",
        end_port: "Busan",
        distance: 10207,
        fuel_consumption: [
            {
                fuel: 1,
                engine: 1,
                amountKg: 195677,
            },
        ],
    },
    {
        created_at: new Date("2025-02-08T02:09:00"),
        ship: "9344595",
        journey_ended_at: new Date("2025-02-08T02:09:00"),
        journey_started_at: new Date("2025-01-23T00:00:00"),
        start_port: "Panama Canal",
        end_port: "Suez Canal",
        distance: 7723,
        fuel_consumption: [
            {
                fuel: 2,
                engine: 1,
                amountKg: 127168,
            },
        ],
    },
    {
        created_at: new Date("2025-02-25T12:45:00"),
        ship: "9561527",
        journey_ended_at: new Date("2025-02-25T12:45:00"),
        journey_started_at: new Date("2025-01-29T00:00:00"),
        start_port: "Port of Felixstowe",
        end_port: "Jebel Ali",
        distance: 13215,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 369236,
            },
        ],
    },
    {
        created_at: new Date("2025-02-04T18:57:00"),
        ship: "9561527",
        journey_ended_at: new Date("2025-02-04T18:57:00"),
        journey_started_at: new Date("2025-01-10T00:00:00"),
        start_port: "Antwerp",
        end_port: "Port of Los Angeles",
        distance: 12379,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 297113,
            },
        ],
    },
    {
        created_at: new Date("2025-01-07T18:24:00"),
        ship: "9561527",
        journey_ended_at: new Date("2025-01-07T18:24:00"),
        journey_started_at: new Date("2025-01-03T00:00:00"),
        start_port: "Port of Kaohsiung",
        end_port: "Port of Los Angeles",
        distance: 2288,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 59143,
            },
        ],
    },
    {
        created_at: new Date("2025-02-18T13:06:00"),
        ship: "9561527",
        journey_ended_at: new Date("2025-02-18T13:06:00"),
        journey_started_at: new Date("2025-01-25T00:00:00"),
        start_port: "Port of Felixstowe",
        end_port: "Busan",
        distance: 11782,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 257468,
            },
        ],
    },
    {
        created_at: new Date("2025-02-10T15:48:00"),
        ship: "9561527",
        journey_ended_at: new Date("2025-02-10T15:48:00"),
        journey_started_at: new Date("2025-01-24T00:00:00"),
        start_port: "Rotterdam",
        end_port: "Panama Canal",
        distance: 8476,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 233558,
            },
        ],
    },
    {
        created_at: new Date("2025-02-01T16:42:00"),
        ship: "9558513",
        journey_ended_at: new Date("2025-02-01T16:42:00"),
        journey_started_at: new Date("2025-01-15T00:00:00"),
        start_port: "Busan",
        end_port: "Antwerp",
        distance: 8494,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 249364,
            },
        ],
    },
    {
        created_at: new Date("2025-01-13T02:39:00"),
        ship: "9558513",
        journey_ended_at: new Date("2025-01-13T02:39:00"),
        journey_started_at: new Date("2025-01-04T00:00:00"),
        start_port: "Hamburg",
        end_port: "Port of Tanjung Pelepas",
        distance: 4373,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 110303,
            },
        ],
    },
    {
        created_at: new Date("2025-02-05T11:09:00"),
        ship: "9558513",
        journey_ended_at: new Date("2025-02-05T11:09:00"),
        journey_started_at: new Date("2025-01-23T00:00:00"),
        start_port: "Port of Kaohsiung",
        end_port: "Busan",
        distance: 6463,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 151783,
            },
        ],
    },
    {
        created_at: new Date("2025-02-05T02:09:00"),
        ship: "9558513",
        journey_ended_at: new Date("2025-02-05T02:09:00"),
        journey_started_at: new Date("2025-01-23T00:00:00"),
        start_port: "Rotterdam",
        end_port: "Port of Tanjung Pelepas",
        distance: 6283,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 143232,
            },
        ],
    },
    {
        created_at: new Date("2025-01-16T02:54:00"),
        ship: "9558513",
        journey_ended_at: new Date("2025-01-16T02:54:00"),
        journey_started_at: new Date("2025-01-01T00:00:00"),
        start_port: "Port of Felixstowe",
        end_port: "Hamburg",
        distance: 7258,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 146122,
            },
        ],
    },
    {
        created_at: new Date("2025-01-27T01:42:00"),
        ship: "9867304",
        journey_ended_at: new Date("2025-01-27T01:42:00"),
        journey_started_at: new Date("2025-01-22T00:00:00"),
        start_port: "Rotterdam",
        end_port: "Port of Hong Kong",
        distance: 2434,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 51836,
            },
        ],
    },
    {
        created_at: new Date("2025-01-21T21:24:00"),
        ship: "9867304",
        journey_ended_at: new Date("2025-01-21T21:24:00"),
        journey_started_at: new Date("2025-01-19T00:00:00"),
        start_port: "Port of Hong Kong",
        end_port: "Jebel Ali",
        distance: 1388,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 39435,
            },
        ],
    },
    {
        created_at: new Date("2025-02-15T22:48:00"),
        ship: "9867304",
        journey_ended_at: new Date("2025-02-15T22:48:00"),
        journey_started_at: new Date("2025-01-24T00:00:00"),
        start_port: "Shanghai",
        end_port: "Busan",
        distance: 11016,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 246946,
            },
        ],
    },
    {
        created_at: new Date("2025-02-18T03:12:00"),
        ship: "9867304",
        journey_ended_at: new Date("2025-02-18T03:12:00"),
        journey_started_at: new Date("2025-01-25T00:00:00"),
        start_port: "Antwerp",
        end_port: "Port of Los Angeles",
        distance: 11584,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 279698,
            },
        ],
    },
    {
        created_at: new Date("2025-01-29T17:18:00"),
        ship: "9867304",
        journey_ended_at: new Date("2025-01-29T17:18:00"),
        journey_started_at: new Date("2025-01-09T00:00:00"),
        start_port: "Port of Hong Kong",
        end_port: "Suez Canal",
        distance: 9946,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 296874,
            },
        ],
    },
    {
        created_at: new Date("2025-02-05T07:21:00"),
        ship: "9353056",
        journey_ended_at: new Date("2025-02-05T07:21:00"),
        journey_started_at: new Date("2025-01-25T00:00:00"),
        start_port: "Busan",
        end_port: "Panama Canal",
        distance: 5427,
        fuel_consumption: [
            {
                fuel: 2,
                engine: 1,
                amountKg: 95539,
            },
        ],
    },
    {
        created_at: new Date("2025-01-23T11:15:00"),
        ship: "9353056",
        journey_ended_at: new Date("2025-01-23T11:15:00"),
        journey_started_at: new Date("2025-01-18T00:00:00"),
        start_port: "Port of Los Angeles",
        end_port: "Port of Felixstowe",
        distance: 2625,
        fuel_consumption: [
            {
                fuel: 2,
                engine: 1,
                amountKg: 45551,
            },
        ],
    },
    {
        created_at: new Date("2025-01-22T09:00:00"),
        ship: "9353056",
        journey_ended_at: new Date("2025-01-22T09:00:00"),
        journey_started_at: new Date("2025-01-07T00:00:00"),
        start_port: "Antwerp",
        end_port: "Port of Tanjung Pelepas",
        distance: 7380,
        fuel_consumption: [
            {
                fuel: 2,
                engine: 1,
                amountKg: 123377,
            },
        ],
    },
    {
        created_at: new Date("2025-02-05T16:33:00"),
        ship: "9353056",
        journey_ended_at: new Date("2025-02-05T16:33:00"),
        journey_started_at: new Date("2025-01-30T00:00:00"),
        start_port: "Port of Kaohsiung",
        end_port: "Hamburg",
        distance: 3211,
        fuel_consumption: [
            {
                fuel: 1,
                engine: 1,
                amountKg: 44668,
            },
        ],
    },
    {
        created_at: new Date("2025-01-13T08:51:00"),
        ship: "9353056",
        journey_ended_at: new Date("2025-01-13T08:51:00"),
        journey_started_at: new Date("2025-01-07T00:00:00"),
        start_port: "Panama Canal",
        end_port: "Rotterdam",
        distance: 3057,
        fuel_consumption: [
            {
                fuel: 1,
                engine: 1,
                amountKg: 49619,
            },
        ],
    },
    {
        created_at: new Date("2025-02-19T07:18:00"),
        ship: "9007080",
        journey_ended_at: new Date("2025-02-19T07:18:00"),
        journey_started_at: new Date("2025-01-24T00:00:00"),
        start_port: "Port of Hong Kong",
        end_port: "Port of Los Angeles",
        distance: 12626,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 332426,
            },
        ],
    },
    {
        created_at: new Date("2025-02-13T18:09:00"),
        ship: "9007080",
        journey_ended_at: new Date("2025-02-13T18:09:00"),
        journey_started_at: new Date("2025-01-21T00:00:00"),
        start_port: "Rotterdam",
        end_port: "Panama Canal",
        distance: 11403,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 339938,
            },
        ],
    },
    {
        created_at: new Date("2025-02-09T01:51:00"),
        ship: "9007080",
        journey_ended_at: new Date("2025-02-09T01:51:00"),
        journey_started_at: new Date("2025-01-15T00:00:00"),
        start_port: "Jebel Ali",
        end_port: "Port of Kaohsiung",
        distance: 12037,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 358675,
            },
        ],
    },
    {
        created_at: new Date("2025-01-24T20:18:00"),
        ship: "9007080",
        journey_ended_at: new Date("2025-01-24T20:18:00"),
        journey_started_at: new Date("2025-01-18T00:00:00"),
        start_port: "Busan",
        end_port: "Antwerp",
        distance: 3286,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 98378,
            },
        ],
    },
    {
        created_at: new Date("2025-02-11T21:39:00"),
        ship: "9007080",
        journey_ended_at: new Date("2025-02-11T21:39:00"),
        journey_started_at: new Date("2025-01-29T00:00:00"),
        start_port: "Hamburg",
        end_port: "Panama Canal",
        distance: 6673,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 181855,
            },
        ],
    },
    {
        created_at: new Date("2025-02-04T14:57:00"),
        ship: "9950966",
        journey_ended_at: new Date("2025-02-04T14:57:00"),
        journey_started_at: new Date("2025-01-16T00:00:00"),
        start_port: "Port of Kaohsiung",
        end_port: "Singapore",
        distance: 9419,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 245463,
            },
        ],
    },
    {
        created_at: new Date("2025-02-05T21:39:00"),
        ship: "9950966",
        journey_ended_at: new Date("2025-02-05T21:39:00"),
        journey_started_at: new Date("2025-01-06T00:00:00"),
        start_port: "Port of Felixstowe",
        end_port: "Port of Kaohsiung",
        distance: 14833,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 395126,
            },
        ],
    },
    {
        created_at: new Date("2025-01-22T00:36:00"),
        ship: "9950966",
        journey_ended_at: new Date("2025-01-22T00:36:00"),
        journey_started_at: new Date("2025-01-20T00:00:00"),
        start_port: "Port of Long Beach",
        end_port: "Singapore",
        distance: 972,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 25707,
            },
        ],
    },
    {
        created_at: new Date("2025-02-03T05:45:00"),
        ship: "9950966",
        journey_ended_at: new Date("2025-02-03T05:45:00"),
        journey_started_at: new Date("2025-01-31T00:00:00"),
        start_port: "Port of Los Angeles",
        end_port: "Busan",
        distance: 1555,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 40026,
            },
        ],
    },
    {
        created_at: new Date("2025-02-15T04:36:00"),
        ship: "9950966",
        journey_ended_at: new Date("2025-02-15T04:36:00"),
        journey_started_at: new Date("2025-01-16T00:00:00"),
        start_port: "Jebel Ali",
        end_port: "Port of Los Angeles",
        distance: 14492,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 300821,
            },
        ],
    },
    {
        created_at: new Date("2025-02-14T04:54:00"),
        ship: "9030453",
        journey_ended_at: new Date("2025-02-14T04:54:00"),
        journey_started_at: new Date("2025-01-24T00:00:00"),
        start_port: "Panama Canal",
        end_port: "Singapore",
        distance: 10178,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 298392,
            },
        ],
    },
    {
        created_at: new Date("2025-02-14T09:57:00"),
        ship: "9030453",
        journey_ended_at: new Date("2025-02-14T09:57:00"),
        journey_started_at: new Date("2025-01-25T00:00:00"),
        start_port: "Rotterdam",
        end_port: "Panama Canal",
        distance: 9799,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 238504,
            },
        ],
    },
    {
        created_at: new Date("2025-02-04T12:15:00"),
        ship: "9030453",
        journey_ended_at: new Date("2025-02-04T12:15:00"),
        journey_started_at: new Date("2025-01-30T00:00:00"),
        start_port: "Suez Canal",
        end_port: "Rotterdam",
        distance: 2645,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 61159,
            },
        ],
    },
    {
        created_at: new Date("2025-01-22T12:36:00"),
        ship: "9030453",
        journey_ended_at: new Date("2025-01-22T12:36:00"),
        journey_started_at: new Date("2025-01-17T00:00:00"),
        start_port: "Port of Tanjung Pelepas",
        end_port: "Port of Felixstowe",
        distance: 2652,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 77017,
            },
        ],
    },
    {
        created_at: new Date("2025-01-28T07:24:00"),
        ship: "9030453",
        journey_ended_at: new Date("2025-01-28T07:24:00"),
        journey_started_at: new Date("2025-01-21T00:00:00"),
        start_port: "Singapore",
        end_port: "Port of Los Angeles",
        distance: 3508,
        fuel_consumption: [
            {
                fuel: 8,
                engine: 3,
                amountKg: 86399,
            },
        ],
    },
];
var getFleet = function (reports) { return [
    {
        name: "Nostromo",
        imo: "9857728",
        engines: [
            {
                type: 1,
                ship_engine_type: 1,
            },
        ],
        company_id: 1,
        fleet_id: 1,
        id: 1,
        pool_id: 1,
        reports: getReports(reports, "9857728"),
    },
    {
        name: "Serenity",
        imo: "9257140",
        engines: [
            {
                type: 3,
                ship_engine_type: 1,
            },
        ],
        company_id: 1,
        fleet_id: 1,
        id: 1,
        pool_id: 1,
        reports: getReports(reports, "9257140"),
    },
    {
        name: "Enterprise",
        imo: "9959339",
        engines: [
            {
                type: 3,
                ship_engine_type: 1,
            },
        ],
        company_id: 1,
        fleet_id: 1,
        id: 1,
        pool_id: 1,
        reports: getReports(reports, "9959339"),
    },
    {
        name: "Millennium Falcon",
        imo: "9444520",
        engines: [
            {
                type: 3,
                ship_engine_type: 1,
            },
        ],
        company_id: 1,
        fleet_id: 1,
        id: 1,
        pool_id: 1,
        reports: getReports(reports, "9444520"),
    },
    {
        name: "Black Pearl",
        imo: "9314848",
        engines: [
            {
                type: 1,
                ship_engine_type: 1,
            },
        ],
        company_id: 1,
        fleet_id: 1,
        id: 1,
        pool_id: 1,
        reports: getReports(reports, "9314848"),
    },
    {
        name: "Flying Dutchman",
        imo: "9247185",
        engines: [
            {
                type: 3,
                ship_engine_type: 1,
            },
        ],
        company_id: 1,
        fleet_id: 1,
        id: 1,
        pool_id: 1,
        reports: getReports(reports, "9247185"),
    },
    {
        name: "Jolly Roger",
        imo: "9492315",
        engines: [
            {
                type: 3,
                ship_engine_type: 1,
            },
        ],
        company_id: 1,
        fleet_id: 1,
        id: 1,
        pool_id: 1,
        reports: getReports(reports, "9492315"),
    },
    {
        name: "Andromeda Ascendant",
        imo: "9357707",
        engines: [
            {
                type: 3,
                ship_engine_type: 1,
            },
        ],
        company_id: 1,
        fleet_id: 1,
        id: 1,
        pool_id: 1,
        reports: getReports(reports, "9357707"),
    },
    {
        name: "Bebop",
        imo: "9023455",
        engines: [
            {
                type: 1,
                ship_engine_type: 1,
            },
        ],
        company_id: 1,
        fleet_id: 1,
        id: 1,
        pool_id: 1,
        reports: getReports(reports, "9023455"),
    },
    {
        name: "Nebuchadnezzar",
        imo: "9267322",
        engines: [
            {
                type: 3,
                ship_engine_type: 1,
            },
        ],
        company_id: 1,
        fleet_id: 1,
        id: 1,
        pool_id: 1,
        reports: getReports(reports, "9267322"),
    },
    {
        name: "Red Dwarf",
        imo: "9848910",
        engines: [
            {
                type: 3,
                ship_engine_type: 1,
            },
        ],
        company_id: 1,
        fleet_id: 1,
        id: 1,
        pool_id: 1,
        reports: getReports(reports, "9848910"),
    },
    {
        name: "Heart of Gold",
        imo: "9056034",
        engines: [
            {
                type: 3,
                ship_engine_type: 1,
            },
        ],
        company_id: 1,
        fleet_id: 1,
        id: 1,
        pool_id: 1,
        reports: getReports(reports, "9056034"),
    },
    {
        name: "Event Horizon",
        imo: "9344595",
        engines: [
            {
                type: 1,
                ship_engine_type: 1,
            },
        ],
        company_id: 1,
        fleet_id: 1,
        id: 1,
        pool_id: 1,
        reports: getReports(reports, "9344595"),
    },
    {
        name: "Galactica",
        imo: "9561527",
        engines: [
            {
                type: 3,
                ship_engine_type: 1,
            },
        ],
        company_id: 1,
        fleet_id: 1,
        id: 1,
        pool_id: 1,
        reports: getReports(reports, "9561527"),
    },
    {
        name: "Discovery One",
        imo: "9558513",
        engines: [
            {
                type: 3,
                ship_engine_type: 1,
            },
        ],
        company_id: 1,
        fleet_id: 1,
        id: 1,
        pool_id: 1,
        reports: getReports(reports, "9558513"),
    },
    {
        name: "Argo",
        imo: "9867304",
        engines: [
            {
                type: 3,
                ship_engine_type: 1,
            },
        ],
        company_id: 1,
        fleet_id: 1,
        id: 1,
        pool_id: 1,
        reports: getReports(reports, "9867304"),
    },
    {
        name: "Rocinante",
        imo: "9353056",
        engines: [
            {
                type: 1,
                ship_engine_type: 1,
            },
        ],
        company_id: 1,
        fleet_id: 1,
        id: 1,
        pool_id: 1,
        reports: getReports(reports, "9353056"),
    },
    {
        name: "Elysium",
        imo: "9007080",
        engines: [
            {
                type: 3,
                ship_engine_type: 1,
            },
        ],
        company_id: 1,
        fleet_id: 1,
        id: 1,
        pool_id: 1,
        reports: getReports(reports, "9007080"),
    },
    {
        name: "Orville",
        imo: "9950966",
        engines: [
            {
                type: 3,
                ship_engine_type: 1,
            },
        ],
        company_id: 1,
        fleet_id: 1,
        id: 1,
        pool_id: 1,
        reports: getReports(reports, "9950966"),
    },
    {
        name: "Proteus",
        imo: "9030453",
        engines: [
            {
                type: 3,
                ship_engine_type: 1,
            },
        ],
        company_id: 1,
        fleet_id: 1,
        id: 1,
        pool_id: 1,
        reports: getReports(reports, "9030453"),
    },
]; };
var roll = function (lowCarbonShare) {
    return lowCarbonShare >= 1 ? true : Math.random() < lowCarbonShare;
};
var getLowCarbonFuelAndEngine = function (fuel, metadata) {
    if (metadata.combinations.fuels[fuel].includes(1)) {
        // ICE fuel
        return {
            fuel: 23,
            engine: 1,
        };
    }
    else if (metadata.combinations.fuels[fuel].includes(3)) {
        // LNG
        return {
            fuel: 24,
            engine: 4,
        };
    }
    return {
        fuel: 23,
        engine: 1,
    };
};
export var getSimulatedReportsAndFleet = function (lowCarbonShare, metadata) {
    var reports = simulated_reports.map(function (report) {
        var consumptions = report.fuel_consumption.map(function (consumption) {
            if (roll(lowCarbonShare)) {
                var energyMJ = 1000 *
                    metadata.fuels[consumption.fuel].energy_content *
                    consumption.amountKg;
                var lowCarbonFuelAndEngine = getLowCarbonFuelAndEngine(consumption.fuel, metadata);
                var newAmountKg = energyMJ /
                    metadata.fuels[lowCarbonFuelAndEngine.fuel].energy_content /
                    1000;
                return __assign(__assign({}, lowCarbonFuelAndEngine), { amountKg: newAmountKg });
            }
            return consumption;
        });
        return __assign(__assign({}, report), { fuel_consumption: consumptions });
    });
    return {
        reports: reports,
        fleet: getFleet(reports),
    };
};
