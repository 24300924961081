var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useAuth0 } from "@auth0/auth0-react";
import useFetch from "../hooks/useFetch";
import { PageContent, TextContent } from "./common-styled-components";
import { getAuthHeader } from "../util/auth";
export default (function () {
    var user = useAuth0().user;
    var token = useFetch("calculator/token.json", undefined, __assign(__assign({}, getAuthHeader(user)), { "Content-Type": "application/json" }))[0];
    console.info(token);
    return (_jsx(PageContent, { children: _jsx(TextContent, __assign({ style: { marginTop: 100, textAlign: "center" } }, { children: token.status === "Success"
                ? "".concat(token.data.token.user_email, ", ").concat(token.data.token.exp)
                : token.status })) }));
});
