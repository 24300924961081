var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import styled from "styled-components";
import { theme } from "../../theme";
import { Selector, SelectorLabel, SmallSubtitle, Grid, } from "../common-styled-components";
import { NumberInput } from "../generic/NumberInput";
import { useParams } from "../../hooks/useParams";
var Panel = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  margin-bottom: ", ";\n"], ["\n  width: 100%;\n  margin-bottom: ", ";\n"])), theme.spacing(4));
export default (function (props) {
    var title = props.title, initialParams = props.initialParams, texts = props.texts, setParams = props.setParams;
    var _a = useState(true), panelOpen = _a[0], _setPanelOpen = _a[1];
    var _b = useParams({
        defaultParams: initialParams,
        setParams: setParams,
    }), localParams = _b.localParams, setParamsHelper = _b.setParamsHelper;
    return (_jsxs(Panel, { children: [_jsx(SmallSubtitle, __assign({ style: { marginBottom: theme.spacing(1) } }, { children: title })), panelOpen && (_jsxs(Grid, { children: [_jsx("div", { children: _jsxs(Selector, { children: [_jsx(SelectorLabel, { children: texts.fleet_size || "Size of fleet" }), _jsx(NumberInput, { value: localParams.fleet_size, onChange: function (value) {
                                        return setParamsHelper({ fleet_size: value });
                                    }, step: 1, min: 1 })] }) }), _jsx("div", { children: _jsxs(Selector, { children: [_jsx(SelectorLabel, { children: texts.emission_allowance_price }), _jsx(NumberInput, { value: localParams.emission_allowance_price, onChange: function (value) {
                                        return setParamsHelper({ emission_allowance_price: value });
                                    }, isFloat: true })] }) })] }))] }));
});
var templateObject_1;
