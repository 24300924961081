import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { routes } from "./util/routes";
import Modal from "react-modal";
var container = document.getElementById("root");
var root = createRoot(container);
var router = createBrowserRouter(routes);
Modal.setAppElement("#root");
root.render(_jsx(React.StrictMode, { children: _jsx(RouterProvider, { router: router }) }));
