var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import { routeNames } from "../../../util/routes";
import { TD, TH, TR } from "../../common-styled-components";
import { complianceOptions, getCompliance, } from "../../../util/compliance/compliance";
import styled from "styled-components";
import { theme } from "../../../theme";
var shipTableColumns = [
    {
        key: "name",
        label: "Name",
    },
    {
        key: "reports",
        label: "Reports",
    },
    {
        key: "carbonIntensity",
        label: "Intensity (gCO₂e/MJ)",
    },
    {
        key: "emissionsBalanceTons",
        label: "Carbon unit balance (tCO₂e)",
    },
];
var ComplianceIcon = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 24px;\n  height: 24px;\n  margin-right: ", ";\n"], ["\n  width: 24px;\n  height: 24px;\n  margin-right: ", ";\n"])), theme.spacing(1));
var CellContent = function (_a) {
    var _b, _c, _d, _e;
    var ship = _a.ship, contentKey = _a.contentKey, compliance = _a.compliance;
    switch (contentKey) {
        case "name":
            return (_jsx(Link, __assign({ to: "".concat(routeNames.admin, "/").concat(routeNames.shipAdmin, "/").concat(ship.id), style: { color: complianceOptions[compliance].color } }, { children: ship.name })));
        case "reports":
            return _jsx("div", { children: (_c = (_b = ship.reports) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : "" });
        case "analysis":
            return ship.analysis ? (_jsxs("div", { children: [ship.analysis.intensities.total.carbonIntensity, " gCO\u2082e/MJ"] })) : (_jsx("div", {}));
        case "carbonIntensity":
            return ship.analysis ? (_jsxs("div", __assign({ style: { color: complianceOptions[compliance].color } }, { children: [_jsx(ComplianceIcon, { src: complianceOptions[compliance].icon }), (_d = ship.analysis.intensities.total[contentKey]) === null || _d === void 0 ? void 0 : _d.toLocaleString("en-US", { maximumFractionDigits: 2 })] }))) : (_jsx("div", {}));
        case "emissionsBalanceTons":
        case "emissionsLimitTons":
        case "co2eEmissionTons":
        case "energyMJ":
            return ship.analysis ? (_jsx("div", __assign({ style: { color: complianceOptions[compliance].color } }, { children: (_e = ship.analysis.intensities.total[contentKey]) === null || _e === void 0 ? void 0 : _e.toLocaleString("en-US", { maximumFractionDigits: 2 }) }))) : (_jsx("div", {}));
        case "engines":
            return _jsx("div", { children: ship.engines.map(function (e) { return e.type; }).join(", ") });
        default:
            return _jsx("div", { children: ship[contentKey] });
    }
};
export var PoolShipTable = function (_a) {
    var fleet = _a.fleet, complianceYear = _a.complianceYear, metadata = _a.metadata;
    return (metadata && (_jsxs("table", { children: [_jsx("thead", { children: _jsxs("tr", { children: [shipTableColumns.map(function (c) { return (_jsx(TH, { children: c.label }, c.key)); }), " "] }) }), _jsx("tbody", { children: fleet.map(function (ship) {
                    var _a;
                    var compliance = getCompliance(metadata, complianceYear, (_a = ship.analysis) === null || _a === void 0 ? void 0 : _a.intensities.total.carbonIntensity);
                    return (_jsx(TR, { children: shipTableColumns.map(function (c) { return (_jsx(TD, { children: _jsx(CellContent, { contentKey: c.key, ship: ship, compliance: compliance }) }, c.key)); }) }, ship.id));
                }) })] })));
};
var templateObject_1;
