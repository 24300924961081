export var getAPIUrl = function () {
    if (process.env.NODE_ENV === "production") {
        return "https://api.ahticlimate.com/v1/";
    }
    return "https://api-dev.ahticlimate.com/v1/";
};
export var LoaderStatus;
(function (LoaderStatus) {
    LoaderStatus["Idle"] = "Idle";
    LoaderStatus["Loading"] = "Loading";
    LoaderStatus["Success"] = "Success";
    LoaderStatus["Error"] = "Error";
})(LoaderStatus || (LoaderStatus = {}));
