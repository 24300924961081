import { theme } from "../../theme";
import intensityLow from "../../../images/intensity-low.png";
import intensityMedium from "../../../images/intensity-medium.png";
import intensityHigh from "../../../images/intensity-high.png";
export var complianceOptions = {
    neutral: {
        color: theme.colors.blue,
        text: "Compliant",
        icon: intensityMedium,
    },
    overcompliant: {
        color: theme.colors.green,
        text: "Overcompliant",
        icon: intensityLow,
    },
    "not compliant": {
        color: theme.colors.black,
        text: "Not compliant",
        icon: intensityHigh,
    },
};
export var getCompliance = function (metadata, year, carbonIntensity) {
    if (carbonIntensity === undefined) {
        return "neutral";
    }
    var complianceLimit = metadata.intensity[year];
    if (carbonIntensity > complianceLimit) {
        return "not compliant";
    }
    if (carbonIntensity > complianceLimit * 0.95) {
        return "neutral";
    }
    return "overcompliant";
};
