var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Select from "react-select";
import useFetch from "../../hooks/useFetch";
import { getAuthHeader } from "../../util/auth";
import { useState } from "react";
import { useDeepMemo } from "../../hooks/useDeepMemo";
import { values } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import { LoaderStatus } from "../../util/apiUtils";
export var PortSearchInput = function (_a) {
    var value = _a.value, onChange = _a.onChange;
    var user = useAuth0().user;
    var _b = useFetch(undefined, undefined, __assign(__assign({}, getAuthHeader(user)), { "Content-Type": "application/json" })), ports = _b[0], setUrl = _b[1];
    var _c = useState(true), emptyOptions = _c[0], setEmptyOptions = _c[1];
    var options = useDeepMemo(function () {
        if (emptyOptions ||
            ports.status !== LoaderStatus.Success ||
            !values(ports.data).length) {
            return [[]];
        }
        return [
            values(ports.data).map(function (port) { return ({
                value: port.un_code,
                label: "".concat(port.name, " (").concat(port.un_code, ")"),
            }); }),
        ];
    }, [emptyOptions, ports])[0];
    var handleInput = function (value) {
        if (value.length < 3) {
            setEmptyOptions(true);
        }
        else {
            setEmptyOptions(false);
            setUrl("calculator/complete?port=".concat(value));
        }
    };
    return (_jsx(Select, { isSearchable: true, placeholder: "\u2026Search for port", value: value !== null && value !== void 0 ? value : null, options: options, onChange: function (v) { return (v !== null ? onChange(v.value, v.label) : null); }, onInputChange: handleInput }));
};
