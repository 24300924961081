import { isOriginFuelParams, isAlternativeFuelParams, } from "../fleet-calculator/calculator-types";
export var isVoyageParams = function (obj) {
    // VoyageParams stuff
    return typeof obj.emission_allowance_price === "number" &&
        typeof obj.imo_number === "number" &&
        typeof obj.ship_type === "number" &&
        typeof obj.draft === "number" &&
        typeof obj.ballast === "boolean" &&
        typeof obj.season === "number" &&
        typeof obj.speed === "number" &&
        typeof obj.departure_port === "string" &&
        typeof obj.departure_port_label === "string" &&
        typeof obj.destination_port === "string" &&
        typeof obj.destination_port_label === "string" &&
        Array.isArray(obj.disabled_canals) &&
        obj.disabled_canals.every(function (item) { return typeof item === "string"; }) &&
        Array.isArray(obj.disabled_passages) &&
        obj.disabled_passages.every(function (item) { return typeof item === "string"; }) &&
        // Fuels
        Array.isArray(obj.fuels) &&
        obj.comparison_fuels.every(isOriginFuelParams) &&
        Array.isArray(obj.comparison_fuels) &&
        obj.comparison_fuels.every(isAlternativeFuelParams);
};
