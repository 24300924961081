var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import styled from "styled-components";
import { theme } from "../../theme";
import { SmallSubtitle, Grid, Selector, SelectorLabel, Button, TitleWithExtraContent, } from "../common-styled-components";
import { isEditable } from "../../util/isEditable";
import { isOriginFuelParams, } from "../fleet-calculator/calculator-types";
import { useFuelParams } from "../../hooks/useParams";
import { FuelSelector } from "../custom/CustomFuelSelect";
import { EngineSelector } from "../custom/CustomEngineSelect";
import { NumberInput } from "../generic/NumberInput";
var Panel = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export default (function (props) {
    var title = props.title, isComparison = props.isComparison, fuelIndex = props.fuelIndex, initialParams = props.initialParams, metadata = props.metadata, texts = props.texts, setParams = props.setParams, hideIntroTexts = props.hideIntroTexts, useFOC = props.useFOC, removeFuel = props.removeFuel;
    var editable = isEditable();
    var _a = useState(hideIntroTexts ? true : false), panelOpen = _a[0], setPanelOpen = _a[1];
    var _b = useFuelParams({
        defaultParams: initialParams,
        isComparison: isComparison,
        fuelIndex: fuelIndex,
        metadata: metadata,
        setParams: setParams,
    }), localParams = _b.localParams, engineOptions = _b.engineOptions, selectedEngineOption = _b.selectedEngineOption, onChangeEngineType = _b.onChangeEngineType, onChangeFuelType = _b.onChangeFuelType, setParamsHelper = _b.setParamsHelper;
    return (_jsxs(Panel, { children: [_jsx(TitleWithExtraContent, __assign({ titleLevel: "SmallSubtitle", extraContent: removeFuel && _jsx(Button, __assign({ onClick: function () { return removeFuel(); } }, { children: "Remove" })) }, { children: title })), _jsx(SmallSubtitle, { style: { marginBottom: theme.spacing(1) } }), _jsxs(Grid, { children: [_jsx(FuelSelector, { isComparison: isComparison, editable: editable, metadata: metadata, onChangeFuelType: onChangeFuelType, params: localParams, setParamsHelper: setParamsHelper, texts: texts }), useFOC && isOriginFuelParams(localParams) && (_jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "Fuel oil consumption (t)" }), _jsx(NumberInput, { value: localParams.foc, onChange: function (value) { return setParamsHelper({ foc: value }); } })] })), _jsx(EngineSelector, { isComparison: isComparison, editable: editable, metadata: metadata, params: localParams, setParamsHelper: setParamsHelper, texts: texts, engineOptions: engineOptions, selectedEngineOption: selectedEngineOption, onChangeEngineType: onChangeEngineType })] })] }));
});
var templateObject_1;
