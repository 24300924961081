var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Button } from "../common-styled-components";
import { CloseIcon, EditIcon } from "../custom/Icons";
import styled from "styled-components";
import { theme } from "../../theme";
var InnerContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: ", " 0;\n"], ["\n  padding: ", " 0;\n"])), theme.spacing(3));
export var ToggleableContent = function (_a) {
    var buttonTitle = _a.buttonTitle, closeText = _a.closeText, onClose = _a.onClose, onOpen = _a.onOpen, children = _a.children, defaultOpen = _a.defaultOpen;
    var _b = useState(defaultOpen !== null && defaultOpen !== void 0 ? defaultOpen : false), open = _b[0], setOpen = _b[1];
    return (_jsxs("div", { children: [_jsx(Button, __assign({ onClick: function (e) {
                    e.preventDefault();
                    if (open && onClose) {
                        onClose();
                    }
                    if (!open && onOpen) {
                        onOpen();
                    }
                    setOpen(!open);
                } }, { children: !open ? (_jsxs(_Fragment, { children: [_jsx(EditIcon, { color: "white" }), _jsx("span", { children: buttonTitle })] })) : (_jsxs(_Fragment, { children: [_jsx(CloseIcon, { color: "white" }), _jsx("span", { children: closeText })] })) })), open && _jsx(InnerContent, { children: children })] }));
};
var templateObject_1;
