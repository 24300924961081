var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useLayoutEffect, useRef, useState } from "react";
export var withParentDimensions = function (Component) {
    var ReturnComponent = function (props) {
        var _a = useParentDimensions(), targetRef = _a[0], parentDimensions = _a[1];
        return (_jsx("div", __assign({ ref: targetRef, style: { width: "100%", boxSizing: "border-box" } }, { children: parentDimensions && (_jsx(Component, __assign({}, props, { parentDimensions: parentDimensions }))) })));
    };
    return ReturnComponent;
};
export var useParentDimensions = function () {
    var targetRef = useRef(null);
    var _a = useState(0), width = _a[0], setWidth = _a[1];
    var _b = useState(0), height = _b[0], setHeight = _b[1];
    var solveDimensions = function () {
        if (targetRef.current && targetRef.current.parentElement) {
            var _a = getComputedStyle(targetRef.current.parentElement), widthFromStyle = _a.width, heightFromStyle = _a.height, paddingLeft = _a.paddingLeft, paddingRight = _a.paddingRight, paddingTop = _a.paddingTop, paddingBottom = _a.paddingBottom;
            var p = function (s) { return (s ? parseFloat(s) : 0); };
            var elementWidth = p(widthFromStyle) - p(paddingLeft) - p(paddingRight);
            var elementHeight = p(heightFromStyle) - p(paddingTop) - p(paddingBottom);
            if (width !== elementWidth) {
                setWidth(elementWidth);
            }
            if (height !== elementHeight) {
                setHeight(elementHeight);
            }
        }
    };
    useLayoutEffect(function () {
        solveDimensions();
        window.addEventListener("resize", solveDimensions, false);
        return function () {
            window.removeEventListener("resize", solveDimensions);
        };
    });
    return [targetRef, { width: width, height: height }];
};
