var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useParams } from "react-router-dom";
import { Button, CenteredRow, Grid, P, PageContent, SectionTitle, Selector, SelectorLabel, SmallSubtitle, TextContent, TitleWithExtraContent, } from "../../common-styled-components";
import { useMetadata } from "../../../hooks/useMetadata";
import { analyzeReports, } from "../../../util/compliance/analyzeReports";
import { useDeepMemo } from "../../../hooks/useDeepMemo";
import LineChart from "../LineChart";
import { breakpoints, theme } from "../../../theme";
import ComplianceKPI from "./ComplianceKPI";
import { getCompliance } from "../../../util/compliance/compliance";
import styled from "styled-components";
import { useState } from "react";
import { getSimulatedReportsAndFleet } from "../../../util/compliance/simulated_reports";
import { NumberInput } from "../../generic/NumberInput";
import { range } from "lodash";
import Select from "react-select";
import { PoolShipTable } from "./PoolShipTable";
import { Modal } from "./Modal";
var DemoPageContent = styled(PageContent)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: flex-start;\n  padding: 0 ", ";\n\n  @media (max-width: ", "px) {\n    padding: 0 ", ";\n  }\n"], ["\n  align-items: flex-start;\n  padding: 0 ", ";\n\n  @media (max-width: ", "px) {\n    padding: 0 ", ";\n  }\n"])), theme.spacing(5), breakpoints.mobilePlus, theme.spacing(4));
var getFuelColor = function (fuel) {
    switch (fuel) {
        case 1:
            return theme.colors.grey(1);
        case 2:
            return theme.colors.grey(2);
        case 8:
            return theme.colors.grey(3);
        case 23:
            return theme.colors.greenScale[0];
        case 24:
            return theme.colors.greenScale[1];
        default:
            return theme.colors.blue;
    }
};
var carbonLimitYearOptions = range(2025, 2055, 5).map(function (y) { return ({
    label: y.toString(),
    value: y,
}); });
export default (function () {
    var _a, _b, _c, _d;
    var poolId = useParams().poolId;
    var metadata = useMetadata()[0];
    var _e = useState(0), lowCarbonShare = _e[0], setLowCarbonShare = _e[1];
    var _f = useState(2025), carbonLimitYear = _f[0], setCarbonLimitYear = _f[1];
    var _g = useState(true), showPredictions = _g[0], setShowPredictions = _g[1];
    var _h = useState({}), modal = _h[0], setModal = _h[1];
    var toggleTransactionModal = function () {
        if (modal.type) {
            setModal({});
        }
        else {
            setModal({
                type: "add",
            });
        }
    };
    var poolAnalysis = useDeepMemo(function () {
        if (metadata) {
            var reportsAndFleetForEachShare = range(0, 101, 1).reduce(function (result, lcShare) {
                var _a;
                var _b;
                var fleetAndReports = getSimulatedReportsAndFleet(lcShare / 100, metadata);
                var analysis = (_b = analyzeReports(fleetAndReports.reports, metadata, 2025, carbonLimitYear)) !== null && _b !== void 0 ? _b : {};
                return __assign(__assign({}, result), (_a = {}, _a[lcShare] = __assign(__assign(__assign({}, fleetAndReports), { fleet: fleetAndReports.fleet.map(function (ship) { return (__assign(__assign({}, ship), { analysis: ship.reports &&
                            analyzeReports(ship.reports, metadata, 2025, carbonLimitYear) })); }) }), analysis), _a));
            }, {});
            return [reportsAndFleetForEachShare];
        }
        return [{}];
    }, [metadata, carbonLimitYear])[0];
    var minCellWidth = 480;
    var intro = (_jsx(_Fragment, { children: _jsxs(TextContent, { children: [_jsx(P, { children: "This demo runs a simulation of 100 fictional voyages for a 20 ship fleet. You can set the approximate share of voyages travelled with a low-carbon fuel source: E-Methanol for ICE ships, and E-LNG for LNG ships." }), _jsx(P, { children: "Changing the options will run a new simulation with different low-carbon journeys, so the numbers will change a little." })] }) }));
    if (lowCarbonShare > 100 || lowCarbonShare < 0) {
        return (_jsxs(DemoPageContent, __assign({ style: { padding: "0 ".concat(theme.spacing(5)) } }, { children: [_jsx(SectionTitle, { children: "Ahti demo pool" }), intro, _jsx(P, __assign({ style: { color: theme.colors.red } }, { children: "Please set the low carbon voyage share between 0 and 100." }))] })));
    }
    if (!metadata) {
        return (_jsx(DemoPageContent, __assign({ style: { padding: "0 ".concat(theme.spacing(5)) } }, { children: _jsx(P, { children: "Loading\u2026" }) })));
    }
    if (poolAnalysis && (poolAnalysis === null || poolAnalysis === void 0 ? void 0 : poolAnalysis[lowCarbonShare])) {
        var _j = poolAnalysis[lowCarbonShare], intensities = _j.intensities, fuelSpend = _j.fuelSpend, fleet = _j.fleet, predictions_1 = _j.predictions;
        return (_jsxs(DemoPageContent, __assign({ style: { padding: "0 ".concat(theme.spacing(5)) } }, { children: [modal.type && (_jsx(Modal, { type: "transaction", isOpen: modal.type !== undefined, closeModal: function () { return setModal({}); }, modalType: modal.type, initialValue: modal.value, submit: function () { return null; } })), _jsx(SectionTitle, { children: "Ahti demo pool" }), intro, _jsxs(CenteredRow, __assign({ gap: 7 }, { children: [_jsx(ComplianceKPI, { compliance: getCompliance(metadata, carbonLimitYear, intensities.total.carbonIntensity), title: "Carbon intensity", unit: "gCO\u2082e/MJ", value: intensities.total.carbonIntensity }), _jsx(ComplianceKPI, { compliance: getCompliance(metadata, carbonLimitYear, intensities.total.carbonIntensity), title: "Carbon unit balance", unit: "tCO\u2082e", value: intensities.total.emissionsBalanceTons })] })), _jsxs(Grid, __assign({ minCellWidth: minCellWidth }, { children: [_jsx("div", { children: _jsx(LineChart, { title: "Pool intensity, 2025", unit: "gCO\u2082e/MJ", data: [
                                    {
                                        name: "Intensity",
                                        color: theme.colors.blackText,
                                        data: intensities.cumulative.map(function (d) { return ({
                                            date: d.date,
                                            value: d.intensity.carbonIntensity,
                                        }); }),
                                        prediction: showPredictions &&
                                            ((_a = predictions_1 === null || predictions_1 === void 0 ? void 0 : predictions_1.intensities) === null || _a === void 0 ? void 0 : _a.carbonIntensity) !== undefined
                                            ? {
                                                date: predictions_1.date,
                                                value: predictions_1.intensities.carbonIntensity,
                                            }
                                            : undefined,
                                    },
                                ], limits: [
                                    {
                                        label: "".concat(carbonLimitYear, " limit"),
                                        value: metadata.intensity[carbonLimitYear],
                                        color: theme.colors.red,
                                    },
                                ] }) }), _jsx("div", { children: _jsx(LineChart, { title: "Pool carbon units", unit: "tCO\u2082e", data: [
                                    {
                                        name: "Carbon unit balance",
                                        color: theme.colors.blackText,
                                        data: intensities.cumulative.map(function (d) { return ({
                                            date: d.date,
                                            value: d.intensity.emissionsBalanceTons,
                                        }); }),
                                        prediction: showPredictions &&
                                            ((_b = predictions_1 === null || predictions_1 === void 0 ? void 0 : predictions_1.intensities) === null || _b === void 0 ? void 0 : _b.emissionsBalanceTons) !== undefined
                                            ? {
                                                date: predictions_1.date,
                                                value: predictions_1.intensities.emissionsBalanceTons,
                                            }
                                            : undefined,
                                    },
                                ] }) }), _jsx("div", { children: _jsx(LineChart, { title: "Cumulative emissions, 2025", unit: "tCO\u2082e", data: [
                                    {
                                        name: "Emissions",
                                        color: theme.colors.blackText,
                                        data: intensities.cumulative.map(function (d) { return ({
                                            date: d.date,
                                            value: d.intensity.co2eEmissionTons,
                                        }); }),
                                        prediction: showPredictions &&
                                            ((_c = predictions_1 === null || predictions_1 === void 0 ? void 0 : predictions_1.intensities) === null || _c === void 0 ? void 0 : _c.co2eEmissionTons) !== undefined
                                            ? {
                                                date: predictions_1.date,
                                                value: predictions_1.intensities.co2eEmissionTons,
                                            }
                                            : undefined,
                                    },
                                    {
                                        name: "Emission limit",
                                        color: theme.colors.red,
                                        dashed: true,
                                        data: intensities.cumulative.map(function (d) { return ({
                                            date: d.date,
                                            value: d.intensity.emissionsLimitTons,
                                        }); }),
                                        prediction: showPredictions &&
                                            ((_d = predictions_1 === null || predictions_1 === void 0 ? void 0 : predictions_1.intensities) === null || _d === void 0 ? void 0 : _d.emissionsLimitTons) !== undefined
                                            ? {
                                                date: predictions_1.date,
                                                value: predictions_1.intensities.emissionsLimitTons,
                                            }
                                            : undefined,
                                    },
                                ] }) }), _jsx("div", { children: _jsx(LineChart, { title: "Cumulative fuel use, 2025", unit: "t", data: fuelSpend.cumulativeFuelTimelineTons.map(function (timelineData, i) {
                                    var _a, _b, _c, _d;
                                    return ({
                                        name: (_b = (_a = metadata.fuels[timelineData.fuel]) === null || _a === void 0 ? void 0 : _a.description) !== null && _b !== void 0 ? _b : "Name not found",
                                        color: getFuelColor(timelineData.fuel),
                                        data: timelineData.timeline.map(function (d) { return ({
                                            date: d.date,
                                            value: d.amountKg,
                                        }); }),
                                        prediction: showPredictions &&
                                            ((_c = predictions_1 === null || predictions_1 === void 0 ? void 0 : predictions_1.fuels) === null || _c === void 0 ? void 0 : _c[timelineData.fuel]) !== undefined
                                            ? {
                                                date: predictions_1.date,
                                                value: (_d = predictions_1 === null || predictions_1 === void 0 ? void 0 : predictions_1.fuels) === null || _d === void 0 ? void 0 : _d[timelineData.fuel],
                                            }
                                            : undefined,
                                    });
                                }) }) })] })), _jsx(TitleWithExtraContent, __assign({ titleLevel: "Section", extraContent: _jsx(Button, __assign({ onClick: function () { return toggleTransactionModal(); } }, { children: "+ Add transaction" })) }, { children: "Ships" })), _jsx(PoolShipTable, { fleet: fleet, metadata: metadata, complianceYear: carbonLimitYear }), _jsx(SmallSubtitle, __assign({ style: { marginTop: 500 } }, { children: "Demo options" })), _jsxs(Grid, __assign({ minCellWidth: 240, maxCellWidth: 240, style: { alignItems: "flex-end" } }, { children: [_jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "% of low-carbon journeys" }), _jsx(NumberInput, { value: Math.round(lowCarbonShare), onChange: function (value) { return setLowCarbonShare(value); }, step: 1, min: 0, max: 100, debounceTime: 50 })] }), _jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "Carbon limit year" }), _jsx(Select, { options: carbonLimitYearOptions, onChange: function (newValue) { var _a; return setCarbonLimitYear((_a = newValue === null || newValue === void 0 ? void 0 : newValue.value) !== null && _a !== void 0 ? _a : 2025); }, value: carbonLimitYearOptions.find(function (o) { return o.value === carbonLimitYear; }), isOptionSelected: function (option) { return option.value === carbonLimitYear; } })] }), _jsx(Button, __assign({ onClick: function () { return setShowPredictions(!showPredictions); } }, { children: showPredictions ? "Hide predictions" : "Show predictions" }))] }))] })));
    }
    return _jsxs(DemoPageContent, { children: ["Pool ", poolId, " not found!"] });
});
var templateObject_1;
