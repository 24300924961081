var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { theme } from "../../theme";
export var smallSelectStyle = {
    control: function (baseStyles) { return (__assign(__assign({}, baseStyles), { width: 180, fontSize: theme.fontSizeNumber(-1), minHeight: "30px", height: "30px", border: "none", boxShadow: "none" })); },
    input: function (baseStyles) { return (__assign(__assign({}, baseStyles), { fontSize: theme.fontSize(-1), textTransform: "uppercase", fontWeight: "bold" })); },
    singleValue: function (baseStyles) { return (__assign(__assign({}, baseStyles), { fontSize: theme.fontSize(-1), textTransform: "uppercase", fontWeight: "bold" })); },
    valueContainer: function (provided, _state) { return (__assign(__assign({}, provided), { height: "30px" })); },
};
