var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { theme } from "../../theme";
import { debounce } from "lodash";
export var SelectorInput = styled.input(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 5px;\n  flex-grow: 1;\n  border: 1px solid\n    ", ";\n  border-radius: 4px;\n  height: 40px;\n  ", ";\n  color: ", ";\n  background-color: ", ";\n"], ["\n  padding: 5px;\n  flex-grow: 1;\n  border: 1px solid\n    ", ";\n  border-radius: 4px;\n  height: 40px;\n  ", ";\n  color: ", ";\n  background-color: ", ";\n"])), function (p) { return (p.disabled ? theme.colors.grey(3) : theme.colors.grey(3)); }, theme.fontSize(-1), function (p) { return (p.disabled ? theme.colors.grey(2) : theme.colors.blackText); }, function (p) {
    return p.disabled ? theme.colors.grey(4) : theme.colors.white;
});
export var TextInput = function (props) {
    var disabled = props.disabled, value = props.value, onChange = props.onChange;
    var _a = useState(value), textContent = _a[0], setTextContent = _a[1];
    useEffect(function () {
        if (value !== textContent) {
            setTextContent(value);
        }
    }, [value]);
    var sendChange = useCallback(debounce(function (value) {
        if (onChange) {
            onChange(value);
        }
    }, 1000), []);
    var onTextEdited = useCallback(function (text) {
        setTextContent(text);
        sendChange(text);
    }, []);
    return (_jsx(SelectorInput, { value: textContent, type: "text", onChange: function (e) { return onTextEdited(e.target.value); }, disabled: disabled }));
};
var templateObject_1;
