var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuth0 } from "@auth0/auth0-react";
import { getAuthHeader } from "../../../util/auth";
import { Button, PageContent } from "../../common-styled-components";
import useEdit from "../../../hooks/useEdit";
import { LoaderStatus } from "../../../util/apiUtils";
export default (function () {
    var user = useAuth0().user;
    var _a = useEdit("admin/test_data.json", __assign(__assign({}, getAuthHeader(user)), { "Content-Type": "application/json" })), editStatus = _a[0], executeEdit = _a[1];
    return (_jsxs(PageContent, { children: [_jsx(Button, __assign({ onClick: function () { return executeEdit({ simple: false }, "PUT"); } }, { children: "Reset test data" })), _jsx(Button, __assign({ onClick: function () { return executeEdit(undefined, "DELETE"); } }, { children: "Delete test data" })), _jsxs("div", { children: [editStatus.status, editStatus.status === LoaderStatus.Loading
                        ? ". This might take a while!"
                        : ""] })] }));
});
