import { HmacSHA256, enc } from "crypto-js";
import { Buffer } from "buffer";
import { add24h, getUnixTimeStamp } from "./timeUtils";
var urlSafe = function (str) {
    return str.replace("+", "-").replace("/", "_").replace(/=+$/, "");
};
// Helper function for URL-safe base64 encoding
var base64UrlEncode = function (str) {
    return urlSafe(Buffer.from(str).toString("base64"));
};
export var getAuthHeader = function (user) {
    var secret = process.env.SECRET_KEY;
    var payload = {
        exp: getUnixTimeStamp(add24h(new Date())),
        user_email: user === null || user === void 0 ? void 0 : user.email,
        user_roles: getUserRoles(user),
    };
    var header = { alg: "HS256", typ: "JWT" };
    var encodedHeader = base64UrlEncode(JSON.stringify(header));
    var encodedPayload = base64UrlEncode(JSON.stringify(payload));
    // create the signature
    var message = encodedHeader + "." + encodedPayload;
    var signature = HmacSHA256(message, secret);
    var encodedSignature = urlSafe(enc.Base64.stringify(signature));
    // generate auth token
    var authToken = "Bearer ".concat(encodedHeader, ".").concat(encodedPayload, ".").concat(encodedSignature);
    return { Authorization: authToken };
};
export var getUserRoles = function (user) {
    return user === null || user === void 0 ? void 0 : user["https://ahti-calculator.netlify.app/roles"];
};
export var isAdmin = function (user) {
    var roles = getUserRoles(user);
    return roles !== undefined && roles.includes("Admin");
};
