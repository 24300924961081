import { jsx as _jsx } from "react/jsx-runtime";
import { useParams } from "react-router-dom";
import { PageContent } from "../../common-styled-components";
export default (function () {
    var shipId = useParams().shipId;
    // const ship = fakeData.ships.find((s) => s.imoNumber === Number(shipId));
    // if (ship) {
    //   return <PageContent>Ship info: {ship.name}</PageContent>;
    // }
    return _jsx(PageContent, { children: "Ship not found!" });
});
