import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Selector, SelectorLabel } from "../../../common-styled-components";
import { TextInput } from "../../../generic/TextInput";
import { NumberInput } from "../../../generic/NumberInput";
export var TransactionEditor = function (_a) {
    var state = _a.state, updateValue = _a.updateValue, modalType = _a.modalType;
    if (modalType === "remove") {
        return _jsx("div", { children: "Remove transaction?" });
    }
    return (_jsxs(_Fragment, { children: [_jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "From ship" }), _jsx(TextInput, { value: "Nostromo" })] }), _jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "To ship" }), _jsx(TextInput, { value: "Serenity" })] }), _jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "Quantity moved (tCO\u2082e)" }), _jsx(NumberInput, { value: 300, 
                        // onChange={(newValue) => updateValue("toShip", newValue)}
                        isFloat: true })] }), _jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "Production cost ($/tCO\u2082e)" }), _jsx(NumberInput, { value: 287, 
                        // onChange={(newValue) => updateValue("toShip", newValue)}
                        isFloat: true })] }), _jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "Alternative cost (penalties, $/tCO\u2082e)" }), _jsx(NumberInput, { value: 647, 
                        // onChange={(newValue) => updateValue("toShip", newValue)}
                        isFloat: true })] }), _jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "EU ETS Benefit ($)" }), _jsx(NumberInput, { value: 213, 
                        // onChange={(newValue) => updateValue("toShip", newValue)}
                        isFloat: true })] }), _jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "Transaction Cost ($/tCO\u2082e)" }), _jsx(NumberInput, { value: 286.5, 
                        // onChange={(newValue) => updateValue("toShip", newValue)}
                        isFloat: true })] }), _jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "Total price ($)" }), _jsx(NumberInput, { value: 85950, 
                        // onChange={(newValue) => updateValue("toShip", newValue)}
                        isFloat: true })] })] }));
};
