var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Button, PageContent, SmallButton, TD, TH, TR, TitleWithExtraContent, } from "../../common-styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import useFetch from "../../../hooks/useFetch";
import { getAuthHeader } from "../../../util/auth";
import useEdit from "../../../hooks/useEdit";
import { Modal } from "./Modal";
import { LoaderStatus } from "../../../util/apiUtils";
import { isNullish } from "../../../util/isNullish";
import { routeNames } from "../../../util/routes";
import { Link } from "react-router-dom";
import { every } from "lodash";
var tableColumns = [
    {
        key: "name",
        label: "Name",
    },
    {
        key: "imo",
        label: "IMO number",
    },
    {
        key: "tools",
        label: "Tools",
    },
];
var CellContent = function (_a) {
    var _b, _c;
    var ship = _a.ship, contentKey = _a.contentKey, editShip = _a.editShip, removeShip = _a.removeShip;
    switch (contentKey) {
        case "name":
            return (_jsx(Link, __assign({ to: "".concat(routeNames.admin, "/").concat(routeNames.shipAdmin, "/").concat(ship.id) }, { children: ship.name })));
        case "tools":
            return (_jsxs("div", { children: [_jsx(SmallButton, __assign({ onClick: function () { return removeShip(ship); } }, { children: "Remove" })), _jsx(SmallButton, __assign({ onClick: function () { return editShip(ship); } }, { children: "Edit" }))] }));
        case "reports":
            return _jsx("div", { children: (_c = (_b = ship.reports) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : "" });
        case "analysis":
            return ship.analysis ? (_jsxs("div", { children: [ship.analysis.intensities.total.carbonIntensity, " gCO\u2082e/MJ"] })) : (_jsx("div", {}));
        case "engines":
            return _jsx("div", { children: ship.engines.map(function (e) { return e.type; }).join(", ") });
        default:
            return _jsx("div", { children: ship[contentKey] });
    }
};
export default (function () {
    var user = useAuth0().user;
    var _a = useFetch("admin/ships.json", undefined, __assign(__assign({}, getAuthHeader(user)), { "Content-Type": "application/json" })), ships = _a[0], _setUrl = _a[1], _setBody = _a[2], refresh = _a[3];
    var _b = useEdit("admin/ship.json", __assign(__assign({}, getAuthHeader(user)), { "Content-Type": "application/json" }), refresh), editStatus = _b[0], executeEdit = _b[1];
    var _c = useState({}), modal = _c[0], setModal = _c[1];
    var addShip = function () {
        setModal({
            type: "add",
            value: {
                engines: [
                    {
                        type: 3,
                        ship_engine_type: 1,
                    },
                ],
            },
        });
    };
    var editShip = function (ship) {
        setModal({
            type: "edit",
            value: ship,
        });
    };
    var removeShip = function (ship) {
        setModal({
            type: "remove",
            value: ship,
        });
    };
    var submit = function (type, value) {
        var _a, _b, _c, _d, _e, _f;
        setModal({});
        if (type === "add") {
            if (!isNullish(value.name) &&
                value.name !== "" &&
                !isNullish(value.fleet_id) &&
                !isNullish(value.engines) &&
                value.engines.length > 0 &&
                every(value.engines, function (engine) {
                    return !isNullish(engine.type) && !isNullish(engine.ship_engine_type);
                })) {
                var data = {
                    name: value.name,
                    imo: value.imo,
                    id: null,
                    fleet_id: (_a = value.fleet_id) !== null && _a !== void 0 ? _a : null,
                    pool_id: (_b = value.pool_id) !== null && _b !== void 0 ? _b : null,
                    company_id: (_c = value.company_id) !== null && _c !== void 0 ? _c : null,
                    engines: value.engines,
                };
                executeEdit(data, "PUT");
            }
            else {
                alert("Empty name");
            }
        }
        if (type === "edit") {
            if (!isNullish(value.name) &&
                value.name !== "" &&
                !isNullish(value.fleet_id) &&
                !isNullish(value.engines) &&
                value.engines.length > 0 &&
                every(value.engines, function (engine) {
                    return !isNullish(engine.type) && !isNullish(engine.ship_engine_type);
                })) {
                var data = {
                    name: value.name,
                    imo: value.imo,
                    id: value.id,
                    fleet_id: (_d = value.fleet_id) !== null && _d !== void 0 ? _d : null,
                    pool_id: (_e = value.pool_id) !== null && _e !== void 0 ? _e : null,
                    company_id: (_f = value.company_id) !== null && _f !== void 0 ? _f : null,
                    engines: value.engines,
                };
                executeEdit(data, "PUT");
            }
            else {
                alert("Empty name");
            }
        }
        if (type === "remove") {
            if (value.id !== undefined) {
                var data = {
                    id: value.id,
                };
                executeEdit(data, "DELETE");
            }
        }
    };
    return (_jsxs(PageContent, { children: [modal.type && (_jsx(Modal, { type: "ship", isOpen: modal.type !== undefined, closeModal: function () { return setModal({}); }, modalType: modal.type, initialValue: modal.value, submit: submit })), _jsx(TitleWithExtraContent, __assign({ titleLevel: "Section", extraContent: _jsx(Button, __assign({ onClick: addShip }, { children: "+ Add" })) }, { children: "Ships" })), _jsxs("table", { children: [_jsx("thead", { children: _jsxs("tr", { children: [tableColumns.map(function (c) { return (_jsx(TH, { children: c.label }, c.key)); }), " "] }) }), _jsx("tbody", { children: ships.status === LoaderStatus.Success &&
                            ships.data.map(function (ship) { return (_jsx(TR, { children: tableColumns.map(function (c) { return (_jsx(TD, { children: _jsx(CellContent, { contentKey: c.key, ship: ship, removeShip: removeShip, editShip: editShip }) }, c.key)); }) }, ship.id)); }) })] })] }));
});
