var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Select from "react-select";
import { Selector, SelectorLabel } from "../../../common-styled-components";
import { getEmptyOption } from "../../../../util/compliance/optionUtil";
import { values } from "lodash";
import { useEffect, useState } from "react";
var emptyEngineOption = getEmptyOption("engine");
var emptyEngineRoleOption = getEmptyOption("engine role");
export var EngineEditor = function (_a) {
    var _b, _c;
    var metadata = _a.metadata, updateEngine = _a.updateEngine, index = _a.index, initialValue = _a.initialValue;
    var _d = useState(initialValue !== null && initialValue !== void 0 ? initialValue : {
        type: 1,
        ship_engine_type: 1,
    }), engine = _d[0], setEngine = _d[1];
    useEffect(function () {
        updateEngine(index, engine);
    }, [engine]);
    var engineOptions = [emptyEngineOption].concat(metadata
        ? values(metadata.engines).map(function (e) { return ({
            label: e.engine.description,
            value: e.engine.engine,
        }); })
        : []);
    var engineRoleOptions = [emptyEngineRoleOption].concat(metadata
        ? Object.keys(metadata.ship_engine_type).map(function (key) { return ({
            label: metadata.ship_engine_type[Number(key)],
            value: Number(key),
        }); })
        : []);
    return (_jsx("div", { children: _jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "Engine" }), _jsx(Select, { options: engineOptions, onChange: function (newValue) {
                        return newValue &&
                            newValue.value !== null &&
                            setEngine(__assign(__assign({}, engine), { type: newValue.value }));
                    }, value: (_b = engineOptions.find(function (o) { return engine.type === o.value; })) !== null && _b !== void 0 ? _b : emptyEngineOption, isOptionSelected: function (option) { return option.value === engine.type; } }), _jsx(Select, { options: engineRoleOptions, onChange: function (newValue) {
                        return newValue &&
                            newValue.value !== null &&
                            setEngine(__assign(__assign({}, engine), { ship_engine_type: newValue.value }));
                    }, value: (_c = engineRoleOptions.find(function (o) { return engine.ship_engine_type === o.value; })) !== null && _c !== void 0 ? _c : emptyEngineRoleOption, isOptionSelected: function (option) {
                        return option.value === engine.ship_engine_type;
                    } })] }) }));
};
