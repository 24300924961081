var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { zoom, zoomTransform } from "d3-zoom";
import { useDeepMemo } from "../../hooks/useDeepMemo";
import { theme } from "../../theme";
import { getProjection, getShapes, useMapFeatures } from "../../util/mapUtils";
import { select } from "d3-selection";
import { withParentDimensions, } from "../generic/withParentDimensions";
import styled from "styled-components";
import { Legend, LegendItem } from "../common-styled-components";
import { isEqual } from "lodash";
var HEIGHT_FACTOR = 0.55;
var MapContainer = styled.figure(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  margin: ", " 0;\n  background-color: ", ";\n  padding: ", ";\n  overflow: hidden;\n"], ["\n  width: 100%;\n  margin: ", " 0;\n  background-color: ", ";\n  padding: ", ";\n  overflow: hidden;\n"])), theme.spacing(2), theme.colors.darkBlue, theme.spacing(2));
var VoyageMap = function (props) {
    var width = props.parentDimensions.width, legs = props.legs, hideLegend = props.hideLegend;
    var height = HEIGHT_FACTOR * width;
    var _a = useState(), zoomState = _a[0], setZoomState = _a[1];
    var mapRef = useRef(null);
    var mapFeatures = useMapFeatures()[0];
    var _b = useDeepMemo(function () {
        var proj = getProjection(width, height, HEIGHT_FACTOR, zoomState || { x: 0, y: 0, k: 1 });
        var shapes = getShapes(proj, mapFeatures);
        return [shapes, proj];
    }, [zoomState, mapFeatures, width, height]), mapShapes = _b[0], projection = _b[1];
    // Use d3 for zoom and pan behavior
    useEffect(function () {
        if (mapRef.current) {
            var mapElement_1 = select(mapRef.current);
            var zoomBehavior = zoom()
                .scaleExtent([1, 8])
                .translateExtent([
                [0, 0],
                [width, height],
            ])
                .on("zoom", function () {
                if (mapElement_1 === null || mapElement_1 === void 0 ? void 0 : mapElement_1.node()) {
                    var newZoomState = zoomTransform(mapElement_1.node());
                    setZoomState(newZoomState);
                }
            });
            mapElement_1.call(zoomBehavior);
        }
    }, [width, height]);
    return width !== undefined && width > 0 ? (_jsxs(MapContainer, { children: [_jsxs("svg", __assign({ width: width, height: height, ref: mapRef, style: { overflow: "hidden" } }, { children: [_jsxs("g", __assign({ style: { pointerEvents: "none" } }, { children: [mapShapes.map(function (d, i) {
                                return d !== null ? (_jsx("path", { fill: theme.colors.dimBlue, d: d }, i)) : undefined;
                            }), legs.map(function (l, i) {
                                var startPoint = projection([
                                    l.start_longitude,
                                    l.start_latitude,
                                ]);
                                var endPoint = projection([l.end_longitude, l.end_latitude]);
                                var color = l.is_inside_eca
                                    ? theme.colors.green
                                    : theme.colors.grey(2);
                                return (startPoint &&
                                    endPoint &&
                                    !isEqual(startPoint, [0, 0]) &&
                                    !isEqual(endPoint, [0, 0]) && (_jsxs("g", { children: [_jsx("circle", { cx: startPoint[0], cy: startPoint[1], r: 2, fill: color, strokeWidth: 0 }), _jsx("line", { x1: startPoint[0], x2: endPoint[0], y1: startPoint[1], y2: endPoint[1], stroke: color, strokeWidth: 2, strokeLinecap: "round" }, "".concat(startPoint[0], "-").concat(startPoint[1])), i === legs.length - 1 && (_jsx("circle", { cx: endPoint[0], cy: endPoint[1], r: 2, fill: color, strokeWidth: 0 }))] }, "".concat(startPoint[0], "-").concat(startPoint[1]))));
                            })] })), _jsx("rect", { x: 0, y: 0, width: width, height: height, fill: "rgba(0,0,0,0)" })] })), !hideLegend && (_jsxs(Legend, { children: [_jsx(LegendItem, { color: theme.colors.green, text: "Inside ECA", textColor: "white" }), _jsx(LegendItem, { color: theme.colors.grey(2), text: "Outside ECA", textColor: "white" })] }))] })) : (_jsx("div", {}));
};
export default withParentDimensions(VoyageMap);
var templateObject_1;
