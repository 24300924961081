var ratio = 1.25;
var baseFontSize = 16;
var fontSizeNumber = function (step) {
    var size = baseFontSize * Math.pow(ratio, step);
    return size;
};
var fontSize = function (step) {
    var size = fontSizeNumber(step);
    return "font-size: ".concat(size, "px");
};
var spacingRatio = 1.618;
var spacingBase = 4;
var spacingNumber = function (step) {
    return spacingBase * Math.pow(spacingRatio, step);
};
var spacing = function (step) {
    return "".concat(spacingNumber(step), "px");
};
var greys = ["#20262C", "#52595F", "#A5ABB0", "#D1D4D7", "#E7E7E7"];
var grey = function (step) {
    return step < 0 ? "black" : step >= greys.length ? "white" : greys[step];
};
export var colors = {
    black: "black",
    white: "#ffffff",
    grey: grey,
    red: "#D12E28",
    bluelink: "#0A41D6",
    darkBlue: "#07153E",
    dimBlue: "#36498D",
    blue: "#0A41D6",
    lightBlue: "#79F2FF",
    lightRed: "#FDB5B0",
    lightGreen: "#4DDFB5",
    green: "#19A476",
    darkGreen: "#096245",
    darkestGreen: "#012117",
    greenScale: ["#19A476", "#096245", "#012117"],
    text: "#0A2761",
    blackText: "#000",
    backgroundLightBlue: "#e9e9f3",
};
export var theme = {
    fontTitle: "\n    font-family: 'Plus Jakarta Sans', sans-serif;\n    font-style: normal;\n    font-weight: 800\n  ",
    fontBold: "\n    font-family: 'Noto Sans', sans-serif;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 1.5\n  ",
    fontNormal: "\n    font-family: 'Noto Sans', sans-serif;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 1.5\n  ",
    fontLabel: "\n    font-family: 'Noto Sans', sans-serif;\n    font-style: normal;\n    font-weight: 400\n  ",
    fontLabelBold: "\n    font-family: 'Noto Sans', sans-serif;\n    font-style: normal;\n    font-weight: 700\n  ",
    spacing: spacing,
    spacingNumber: spacingNumber,
    fontSize: fontSize,
    fontSizeNumber: fontSizeNumber,
    colors: colors,
};
export var breakpoints = {
    mobile: 480,
    mobilePlus: 640,
    tablet: 800,
    desktop: 1220,
};
