import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Selector, SelectorLabel } from "../../../common-styled-components";
import { TextInput } from "../../../generic/TextInput";
export var PoolEditor = function (_a) {
    var _b;
    var state = _a.state, updateValue = _a.updateValue, modalType = _a.modalType;
    if (modalType === "remove") {
        return _jsxs("div", { children: ["Remove ", state.name, "?"] });
    }
    return (_jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "Pool name" }), _jsx(TextInput, { value: (_b = state.name) !== null && _b !== void 0 ? _b : "", onChange: function (newValue) { return updateValue("name", newValue); } })] }));
};
