var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { theme } from "../../theme";
import { Selector, SelectorLabel, SmallSubtitle, Grid, } from "../common-styled-components";
import { NumberInput } from "../generic/NumberInput";
import { useParams } from "../../hooks/useParams";
import Select from "react-select";
import { PortSearchInput } from "../generic/PortSearchInput";
var Panel = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  margin-bottom: ", ";\n"], ["\n  width: 100%;\n  margin-bottom: ", ";\n"])), theme.spacing(4));
var loadingOptions = [
    {
        value: false,
        label: "Laden",
    },
    {
        value: true,
        label: "Ballast",
    },
];
var findLoadingOption = function (value) {
    return loadingOptions.find(function (v) { return v.value === value; });
};
var seasonOptions = [
    {
        value: 1,
        label: "Winter",
    },
    {
        value: 2,
        label: "Spring",
    },
    {
        value: 3,
        label: "Summer",
    },
    {
        value: 4,
        label: "Autumn",
    },
];
var findSeasonOption = function (value) {
    return seasonOptions.find(function (v) { return v.value === value; });
};
export default (function (props) {
    var _a, _b, _c, _d;
    var title = props.title, initialParams = props.initialParams, metadata = props.metadata, texts = props.texts, setParams = props.setParams, hideIntroTexts = props.hideIntroTexts;
    var _e = useParams({
        defaultParams: initialParams,
        setParams: setParams,
    }), localParams = _e.localParams, setParamsHelper = _e.setParamsHelper;
    return (_jsxs(Panel, { children: [_jsx(SmallSubtitle, __assign({ style: { marginBottom: theme.spacing(1) } }, { children: title })), _jsxs(Grid, { children: [_jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "IMO number" }), _jsx(NumberInput, { value: localParams.imo_number, onChange: function (value) { return setParamsHelper({ imo_number: value }); } })] }), _jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "Loading status" }), _jsx(Select, { options: loadingOptions, onChange: function (newValue) {
                                    return setParamsHelper({ ballast: newValue === null || newValue === void 0 ? void 0 : newValue.value });
                                }, value: localParams.ballast !== undefined
                                    ? findLoadingOption(localParams.ballast)
                                    : undefined, isOptionSelected: function (option) { return option.value === localParams.ballast; } })] }), _jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "Draft" }), _jsx(NumberInput, { value: localParams.draft, onChange: function (value) { return setParamsHelper({ draft: value }); }, min: 1, isFloat: true })] }), _jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "Speed (kn)" }), _jsx(NumberInput, { value: localParams.speed, onChange: function (value) { return setParamsHelper({ speed: value }); }, min: 1, isFloat: true })] }), _jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "Voyage season" }), _jsx(Select, { options: seasonOptions, onChange: function (newValue) {
                                    return setParamsHelper({ season: newValue === null || newValue === void 0 ? void 0 : newValue.value });
                                }, value: localParams.season !== undefined
                                    ? findSeasonOption(localParams.season)
                                    : undefined, isOptionSelected: function (option) { return option.value === localParams.season; } })] }), _jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "Departure port" }), _jsx(PortSearchInput, { value: {
                                    value: (_a = localParams.departure_port) !== null && _a !== void 0 ? _a : "",
                                    label: (_b = localParams.departure_port_label) !== null && _b !== void 0 ? _b : "",
                                }, onChange: function (value, name) {
                                    return setParamsHelper({
                                        departure_port: value,
                                        departure_port_label: name,
                                    });
                                } })] }), _jsxs(Selector, { children: [_jsx(SelectorLabel, { children: "Destination port" }), _jsx(PortSearchInput, { value: {
                                    value: (_c = localParams.destination_port) !== null && _c !== void 0 ? _c : "",
                                    label: (_d = localParams.destination_port_label) !== null && _d !== void 0 ? _d : "",
                                }, onChange: function (value, name) {
                                    return setParamsHelper({
                                        destination_port: value,
                                        destination_port_label: name,
                                    });
                                } })] }), _jsxs(Selector, { children: [_jsx(SelectorLabel, { children: texts.emission_allowance_price }), _jsx(NumberInput, { value: localParams.emission_allowance_price, onChange: function (value) {
                                    return setParamsHelper({ emission_allowance_price: value });
                                } })] })] })] }));
});
var templateObject_1;
